import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ClientEnvironment } from '@polyai/common/constants/global.constants';
import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { HTTPError } from 'api/error';
import {
  AllTelephonyTokensResponse,
  TelephonyTokenResponse,
} from 'api/resources/telephony/types';
import { PROJECT_RESOURCE, useProjectResource } from './useProjectResources';
import { useToast } from './useToast';

export const TELEPHONE_MUTATION_KEY = 'jupiter-telephony';

const useTelephony = () => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();

  const queryClient = useQueryClient();
  const toast = useToast();

  const queryKey = [accountId, projectId, TELEPHONE_MUTATION_KEY];
  const allConnectionTokensQueryKey = [
    accountId,
    projectId,
    'jupiter-telephony',
    'all-environments',
  ];
  const { isProjectResourceEnabled } = useProjectResource();
  const isEnabled =
    isProjectResourceEnabled(PROJECT_RESOURCE.SETTINGS) ||
    isProjectResourceEnabled(PROJECT_RESOURCE.HANDOFFS);

  const { data, isLoading: isLoadingConnectionToken } =
    useQuery<TelephonyTokenResponse>(queryKey, () => api.getConnectionToken(), {
      enabled: isEnabled,
      retry: false,
    });

  const { data: allConnectionTokens, isLoading: isLoadingAllConnectionTokens } =
    useQuery<AllTelephonyTokensResponse>(
      allConnectionTokensQueryKey,
      () => api.getAllConnectionTokens(),
      {
        enabled: isEnabled,
        retry: false,
      },
    );

  const {
    mutate: createTelephonyConnector,
    isLoading: isCreatingTelephonyConnector,
  } = useMutation<
    TelephonyTokenResponse,
    HTTPError,
    { clientEnv: ClientEnvironment }
  >(({ clientEnv }) => api.createConnector(clientEnv), {
    retry: false,
    mutationKey: queryKey,
    onSuccess: (_, { clientEnv }) => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(allConnectionTokensQueryKey);
      toast.success({
        title: `${clientEnv} SIP address generated`,
      });
    },
  });

  return useMemo(
    () => ({
      connectionToken: data?.token,
      isLoadingConnectionToken,
      createTelephonyConnector,
      isCreatingTelephonyConnector,
      allConnectionTokens,
      isLoadingAllConnectionTokens,
    }),
    [
      createTelephonyConnector,
      data?.token,
      isCreatingTelephonyConnector,
      isLoadingConnectionToken,
      allConnectionTokens,
      isLoadingAllConnectionTokens,
    ],
  );
};

export default useTelephony;
