import { ReactNode } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import { Tooltip } from "components/atoms/Tooltip";

import * as Styled from "./HelpText.styled";

export interface HelpTextProps {
  help?: ReactNode;
}

const HelpText: ComponentWithChildren<HelpTextProps> = ({ children, help }) => {
  return (
    <ConditionalWrapper
      condition={!!help}
      wrapper={(children) => (
        <Styled.TooltipWrapper>
          <Tooltip
            position="top left"
            size="sm"
            trigger={
              <Styled.LabelWithHelp
                data-test-id="help-text-label"
                role="tooltip"
              >
                {children}
              </Styled.LabelWithHelp>
            }
          >
            {help}
          </Tooltip>
        </Styled.TooltipWrapper>
      )}
    >
      <span>{children}</span>
    </ConditionalWrapper>
  );
};

export default HelpText;
