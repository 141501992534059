import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FunctionCall } from 'api/types';

interface FunctionTestPanelState {
  functionToTest?: FunctionCall;
  isFunctionTestPanelOpen: boolean;
}

const initialState: FunctionTestPanelState = {
  isFunctionTestPanelOpen: false,
};

const functionTestPanelSlice = createSlice({
  name: 'functionTestPanel',
  initialState,
  reducers: {
    openFunctionTestPanel: (state) => {
      state.isFunctionTestPanelOpen = true;
    },
    closeFunctionTestPanel: (state) => {
      state.isFunctionTestPanelOpen = false;
    },
    setFunctionToTest: (
      state,
      { payload: functionToTest }: PayloadAction<FunctionCall>,
    ) => {
      state.functionToTest = functionToTest;
    },
  },
});

export const {
  openFunctionTestPanel,
  closeFunctionTestPanel,
  setFunctionToTest,
} = functionTestPanelSlice.actions;

export default functionTestPanelSlice.reducer;
