import React, { CSSProperties, ReactElement, useRef } from "react";

import {
  EventType,
  PopupActions,
  PopupPosition,
} from "reactjs-popup/dist/types";

import "reactjs-popup/dist/index.css";
import * as Styled from "./Tooltip.styled";

type TooltipType = {
  position?: PopupPosition;
  trigger?: ReactElement;
  on?: EventType[];
  open?: boolean;
  style?: CSSProperties;
  timedDismissAfterClick?: boolean;
  disabled?: boolean;
  size?: keyof typeof Styled.TooltipSize;
  borderRadius?: number;
  fitContent?: boolean;
  inline?: boolean;
  maxWidth?: number;
};

export { TooltipWrapper } from "./Tooltip.styled";

export const Tooltip: React.FC<React.PropsWithChildren<TooltipType>> = ({
  children,
  position = "top center",
  trigger,
  on = ["hover", "focus"],
  open,
  style,
  timedDismissAfterClick = true,
  disabled = false,
  size = "lg",
  borderRadius = 12,
  fitContent = false,
  inline,
  maxWidth,
  ...rest
}) => {
  const tooltipRef = useRef<PopupActions>(null);
  const MILLISECONDS_TO_DISMISS_TOOLTIP = 2000;

  // Tooltip will auto close after 2 seconds
  const handleClickDismiss = () => {
    if (on.includes("click") && timedDismissAfterClick) {
      setTimeout(
        () => tooltipRef.current && tooltipRef.current.close(),
        MILLISECONDS_TO_DISMISS_TOOLTIP
      );
    }
  };

  return (
    <Styled.TooltipPopup
      ref={tooltipRef}
      $borderRadius={borderRadius}
      $fitContent={fitContent}
      $maxWidth={maxWidth}
      $size={size}
      contentStyle={style}
      disabled={disabled}
      on={on}
      open={open}
      position={position}
      trigger={
        <Styled.TriggerWrapper
          $inline={inline}
          $isDisabled={disabled}
          role="tooltip"
        >
          {trigger}
        </Styled.TriggerWrapper>
      }
      closeOnDocumentClick
      keepTooltipInside
      onOpen={handleClickDismiss}
      {...rest}
    >
      <Styled.TooltipContent>{children}</Styled.TooltipContent>
    </Styled.TooltipPopup>
  );
};
