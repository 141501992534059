import { useCallback, useEffect, useMemo } from 'react';

import { ClientEnv } from 'constants/Chat.constants';
import { useMutation } from '@tanstack/react-query';

import { resetChat } from 'actions';
import api from 'api';
import { HTTPError } from 'api/error';
import { RespondResponse } from 'api/resources/chat/types';
import { ChatMessage } from 'api/types';
import {
  addAgentResponse,
  addUserMessage,
  setChatMessageDraft,
  setConversationId,
  setIsEnded,
  setIsErrored,
  setIsLoading,
  setIsWaitingSlowResponse,
} from 'ducks/chat/chatSlice';
import { closeChatPanel, setClientEnv } from 'ducks/chatPanel/chatPanelSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useToast } from 'hooks/useToast';
import useBalance from './useBalance';
import useLanguages from './useLanguages';

const MAX_AGENT_DELAYED_RESPONSE_RETRIES = 10;
const SLOW_RESPONSE_THRESHOLD = 1500;

export const ASSISTANT_CHAT_MUTATION_KEY = 'assistant_chat';

export const useChat = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { defaultLanguageCode } = useLanguages();
  const { balance, isBillingEnabled } = useBalance();

  const { id: accountId } = useAppSelector((state) => state.account);
  const { id: projectId } = useAppSelector((state) => state.project);
  const { conversationIds, messageDraft, isLoading, isWaitingSlowResponse } =
    useAppSelector((state) => state.chat);

  const balanceRemaining = useMemo(
    () => !isBillingEnabled || (balance && balance.last_balance > 0),
    [balance, isBillingEnabled],
  );

  const { mutate: startChat } = useMutation<
    RespondResponse,
    HTTPError,
    { clientEnv: ClientEnv; languageCode: string }
  >(
    ({ clientEnv, languageCode }) =>
      api.startChat(accountId, projectId, clientEnv, languageCode),
    {
      mutationKey: [ASSISTANT_CHAT_MUTATION_KEY],
      onMutate: ({ clientEnv }) => {
        dispatch(setIsLoading({ clientEnv, isLoading: true }));
      },
      onSuccess: (data, { clientEnv }) => {
        setIsErrored({ clientEnv, isErrored: false });
        dispatch(
          setConversationId({
            clientEnv,
            conversationId: data.conversation_id,
          }),
        );
        const agentMessage: ChatMessage = {
          metadata: data.metadata,
          text: data.response,
          from: 'agent',
        };
        dispatch(
          addAgentResponse({
            clientEnv,
            agentMessage,
            previousMessageMetadata: data.metadata,
          }),
        );
      },
      onError: (_, { clientEnv }) => {
        dispatch(setIsErrored({ clientEnv, isErrored: true }));
        toast.error({
          title: 'Failed to start chat. Please try again later',
        });
      },
      onSettled: (_d, _e, { clientEnv }) => {
        dispatch(
          setIsLoading({
            clientEnv,
            isLoading: false,
          }),
        );
      },
    },
  );

  const { mutate: startDraftChat } = useMutation(
    (languageCode: string) =>
      api.startDraftChat(accountId, projectId, languageCode),
    {
      mutationKey: [ASSISTANT_CHAT_MUTATION_KEY],
      onMutate: () => {
        dispatch(setIsLoading({ clientEnv: ClientEnv.DRAFT, isLoading: true }));
      },
      onSuccess: (data) => {
        dispatch(
          setIsErrored({ clientEnv: ClientEnv.DRAFT, isErrored: false }),
        );
        dispatch(
          setConversationId({
            clientEnv: ClientEnv.DRAFT,
            conversationId: data.conversation_id,
          }),
        );
        const agentMessage: ChatMessage = {
          metadata: data.metadata,
          text: data.response,
          from: 'agent',
        };
        dispatch(
          addAgentResponse({
            clientEnv: ClientEnv.DRAFT,
            agentMessage,
            previousMessageMetadata: data.metadata,
          }),
        );
      },
      onError: () => {
        dispatch(setIsErrored({ clientEnv: ClientEnv.DRAFT, isErrored: true }));
        toast.error({
          title: 'Failed to start chat. Please try again later',
        });
      },
      onSettled: () => {
        dispatch(
          setIsLoading({
            clientEnv: ClientEnv.DRAFT,
            isLoading: false,
          }),
        );
      },
    },
  );

  const {
    mutate: sendMessage,
    mutateAsync: sendMessageAsync,
    isLoading: isSendingMessage,
  } = useMutation<
    RespondResponse,
    HTTPError,
    {
      clientEnv: ClientEnv;
      message: string;
      isDelayedResponse?: boolean;
      languageCode?: string;
    }
  >(
    ({
      clientEnv,
      message,
      isDelayedResponse = false,
      languageCode = defaultLanguageCode,
    }) => {
      if (!isDelayedResponse) {
        const newChatMessage: ChatMessage = {
          from: 'user',
          text: message,
        };
        dispatch(addUserMessage({ clientEnv, message: newChatMessage }));
      }
      return api.respond(
        accountId,
        projectId,
        conversationIds[clientEnv] as string,
        clientEnv,
        { message, asr_lang_code: languageCode, tts_lang_code: languageCode },
      );
    },
    {
      mutationKey: [ASSISTANT_CHAT_MUTATION_KEY],
      onSuccess: (data, { clientEnv }) => {
        if (data.delayed_response) {
          if (!data.response) {
            return;
          }
        }

        const agentMessage: ChatMessage = {
          metadata: data.metadata,
          text: data.response,
          from: 'agent',
        };
        dispatch(
          addAgentResponse({
            clientEnv,
            agentMessage,
            previousMessageMetadata: data.metadata,
          }),
        );
        dispatch(
          setIsEnded({ clientEnv, isEnded: data?.conversation_ended === true }),
        );
      },
      onError: (error, { clientEnv }) => {
        switch (error.code) {
          case 'ChatInvalidArgument':
            const data = error.data(error.code);
            console.log(data.details);
            break;

          default:
            break;
        }

        dispatch(setIsErrored({ clientEnv, isErrored: true }));
        toast.error({
          title: 'Error while trying to chat with assistant',
        });
      },
      onSettled: () => {
        if (isWaitingSlowResponse) {
          setIsWaitingSlowResponse(false);
        }
      },
    },
  );

  const {
    mutate: sendDraftMessage,
    mutateAsync: sendDraftMessageAsync,
    isLoading: isSendingDraftMessage,
  } = useMutation<
    RespondResponse,
    HTTPError,
    { message: string; isDelayedResponse?: boolean; languageCode?: string }
  >(
    ({
      message,
      isDelayedResponse = false,
      languageCode = defaultLanguageCode,
    }) => {
      if (!isDelayedResponse) {
        const newChatMessage: ChatMessage = {
          from: 'user',
          text: message,
        };
        dispatch(
          addUserMessage({
            clientEnv: ClientEnv.DRAFT,
            message: newChatMessage,
          }),
        );
      }
      return api.draftRespond(
        accountId,
        projectId,
        conversationIds[ClientEnv.DRAFT] as string,
        {
          message,
          asr_lang_code: languageCode,
          tts_lang_code: languageCode,
        },
      );
    },
    {
      mutationKey: [ASSISTANT_CHAT_MUTATION_KEY],
      onSuccess: (data) => {
        if (data.delayed_response) {
          if (!data.response) {
            return;
          }
        }

        const agentMessage: ChatMessage = {
          metadata: data.metadata,
          text: data.response,
          from: 'agent',
        };
        dispatch(
          addAgentResponse({
            clientEnv: ClientEnv.DRAFT,
            agentMessage,
            previousMessageMetadata: data.metadata,
          }),
        );
        dispatch(
          setIsEnded({
            clientEnv: ClientEnv.DRAFT,
            isEnded: data?.conversation_ended === true,
          }),
        );
      },
      onError: () => {
        dispatch(setIsWaitingSlowResponse(false));
        dispatch(setIsErrored({ clientEnv: ClientEnv.DRAFT, isErrored: true }));
        toast.error({
          title: 'Error while trying to chat with assistant',
        });
      },
      onSettled: () => {
        if (isWaitingSlowResponse) {
          setIsWaitingSlowResponse(false);
        }
      },
    },
  );

  const onDraftMessageChange = useCallback(
    (clientEnv: ClientEnv, message: string) =>
      dispatch(setChatMessageDraft({ clientEnv, message })),
    [dispatch],
  );

  const onSendChatMessage = useCallback(
    async (
      clientEnv: ClientEnv,
      isDelayedResponse: boolean = false,
      languageCode: string = defaultLanguageCode,
    ) => {
      onDraftMessageChange(clientEnv, '');

      let maxRetries = MAX_AGENT_DELAYED_RESPONSE_RETRIES;
      const message = isDelayedResponse ? '' : messageDraft[clientEnv];
      onDraftMessageChange(clientEnv, '');

      const slowResponseTimer = setTimeout(() => {
        dispatch(setIsWaitingSlowResponse(true));
      }, SLOW_RESPONSE_THRESHOLD);
      let res: RespondResponse;
      if (clientEnv === ClientEnv.DRAFT) {
        res = await sendDraftMessageAsync({
          message,
          isDelayedResponse,
          languageCode,
        });
      } else {
        res = await sendMessageAsync({
          clientEnv,
          message,
          isDelayedResponse,
          languageCode,
        });
      }
      clearTimeout(slowResponseTimer);
      dispatch(setIsWaitingSlowResponse(false));

      if (res.delayed_response && maxRetries > 0) {
        setTimeout(() => {
          onSendChatMessage(clientEnv, true);
          maxRetries -= 1;
        }, 500);
      }
    },
    [
      dispatch,
      defaultLanguageCode,
      messageDraft,
      onDraftMessageChange,
      sendMessageAsync,
      sendDraftMessageAsync,
    ],
  );

  const onChatCloseClicked = useCallback(() => {
    dispatch(closeChatPanel());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(closeChatPanel());
    };
  }, [dispatch]);

  const onResetConversation = useCallback(
    (clientEnv: ClientEnv) => {
      dispatch(resetChat(clientEnv));
    },
    [dispatch],
  );

  const triggerNewConversation = useCallback(
    (clientEnv: ClientEnv, languageCode: string) => {
      dispatch(resetChat(clientEnv));
      if (balanceRemaining && !isLoading[clientEnv]) {
        if (clientEnv === ClientEnv.DRAFT) {
          startDraftChat(languageCode);
        } else {
          startChat({ clientEnv, languageCode });
        }
      }
    },
    [dispatch, balanceRemaining, startChat, startDraftChat, isLoading],
  );

  const setChatClientEnv = (clientEnv: ClientEnv) => {
    dispatch(setClientEnv(clientEnv));
  };

  return {
    conversationIds,
    balanceRemaining,
    onChatCloseClicked,
    onDraftMessageChange,
    onSendChatMessage,
    triggerNewConversation,
    onResetConversation,
    sendMessage,
    sendDraftMessage,
    setChatClientEnv,
    isSendingDraftMessage,
    isSendingMessage,
  };
};
