import React, {
  ComponentProps,
  ComponentType,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ADD_CREDIT_EMAIL,
  ADD_CREDIT_SUBJECT,
  ADD_CREDIT_TEXT,
  ADD_CREDIT_TITLE,
} from 'constants/EmailUs.constants';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PopupActions } from 'reactjs-popup/dist/types';

import { createAddCreditBody } from '@polyai/common/lib/emailHelper';
import AccordionGroup from '@polyai/ui/components/atoms/AccordionGroup';
import Button from '@polyai/ui/components/atoms/Button';
import { CreditIcon } from '@polyai/ui/components/atoms/Icons';
import CurrencyInput from '@polyai/ui/components/atoms/Input/CurrencyInput';
import Skeleton from '@polyai/ui/components/atoms/Skeleton';
import {
  Body,
  BodyStrong,
  Caption,
  Title,
} from '@polyai/ui/components/atoms/Text';
import { Tooltip } from '@polyai/ui/components/atoms/Tooltip';
import Modal, { ModalButtons } from '@polyai/ui/components/molecules/Modal';

import EmailUsModal from 'components/organisms/EmailUsModal';

import useBalance from 'hooks/useBalance';
import { useIsFeatureOn } from 'hooks/useFeatureSwitch';
import usePayment from 'hooks/usePayment';
import { getActionName } from 'lib/ddlogHelper';
import { determineBonus, formatCurrency } from 'lib/paymentHelper';
import { isBillingEnabled } from './billing.constants';

import * as Styled from './UserBilling.styled';

interface UserBillingProps {
  justify?: 'end' | 'space-between';
}

const stripePromise =
  process?.env?.NEXT_PUBLIC_STRIPE_KEY &&
  loadStripe(process?.env?.NEXT_PUBLIC_STRIPE_KEY ?? '');

enum Bundle {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  CUSTOM = 'custom',
}

const BUNDLE_AMOUNTS = [
  {
    amount: 50,
    id: Bundle.SMALL,
  },
  {
    amount: 100,
    id: Bundle.MEDIUM,
  },
  {
    amount: 250,
    id: Bundle.LARGE,
  },
  {
    amount: null,
    id: Bundle.CUSTOM,
  },
];

const ifBillingEnabled =
  <T extends {}>(Component: ComponentType<T>): ComponentType<T> =>
  (props: T) => {
    if (!isBillingEnabled) {
      return null;
    }

    return <Component {...props} />;
  };

export const UserBillingModal = ifBillingEnabled(
  (props: {
    nested?: ComponentProps<typeof Modal>['nested'];
    trigger: ComponentProps<typeof EmailUsModal>['trigger'];
  }) => {
    const { isFeatureOn } = useIsFeatureOn();

    return isFeatureOn('stripe-payments') && stripePromise ? (
      <StripePaymentModal {...props} />
    ) : (
      <EmailPaymentModal {...props} />
    );
  },
);

export const EmailPaymentModal = (props: {
  nested?: ComponentProps<typeof Modal>['nested'];
  trigger: ComponentProps<typeof EmailUsModal>['trigger'];
}) => {
  const { user } = useAuth0();

  return (
    <EmailUsModal
      body={createAddCreditBody(user?.given_name, user?.family_name)}
      description={ADD_CREDIT_TEXT}
      email={ADD_CREDIT_EMAIL}
      subject={ADD_CREDIT_SUBJECT}
      title={ADD_CREDIT_TITLE}
      {...props}
    />
  );
};

export const StripePaymentModal = (props: {
  nested?: ComponentProps<typeof Modal>['nested'];
  trigger: ComponentProps<typeof Modal>['trigger'];
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<Bundle>();
  const choiceModalRef = useRef<PopupActions>(null);
  const [amount, setAmount] = useState<number | undefined>();
  const {
    openCheckout,
    closeCheckout,
    isOpeningCheckout,
    clientSecret,
    refreshBalance,
  } = usePayment();
  const { balance } = useBalance();

  const amounts = useMemo(() => {
    if (selectedBundle === Bundle.CUSTOM && amount) {
      return {
        credits: amount,
        bonus: determineBonus(amount),
      };
    }
    if (
      selectedBundle !== Bundle.CUSTOM &&
      BUNDLE_AMOUNTS.some((b) => b.id === selectedBundle)
    ) {
      const credits = BUNDLE_AMOUNTS.find((b) => b.id === selectedBundle)!
        .amount!;
      return {
        credits,
        bonus: determineBonus(credits),
      };
    }
  }, [amount, selectedBundle]);

  const handleOpenCheckout = () => {
    datadogRum.addAction(getActionName('Billing', 'open', 'credit checkout'), {
      balance: balance?.last_balance,
      amount: amounts?.credits,
    });
    openCheckout({ amount: amounts?.credits! });
  };

  const handleCompleteCheckout = () => {
    setIsComplete(true);
    refreshBalance();
    datadogRum.addAction(
      getActionName('Billing', undefined, 'credit checkout', 'complete'),
      {
        newBalance: balance?.last_balance,
      },
    );
  };

  if (!stripePromise) {
    return null;
  }

  return (
    <Modal
      {...props}
      ref={choiceModalRef}
      size="xl"
      onClose={() => {
        setSelectedBundle(undefined);
        setAmount(undefined);
        closeCheckout();
        if (isComplete) {
          refreshBalance();
        }
        setIsComplete(false);
      }}
    >
      {clientSecret ? (
        <>
          <EmbeddedCheckoutProvider
            options={{
              clientSecret,
              onComplete: handleCompleteCheckout,
            }}
            stripe={stripePromise}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>

          <ModalButtons goBack={!isComplete ? closeCheckout : undefined}>
            <Button
              label="Close"
              variant={isComplete ? 'PRIMARY' : 'SECONDARY'}
              onClick={() => choiceModalRef.current?.close()}
            />
          </ModalButtons>
        </>
      ) : (
        <>
          <Title>Add credit</Title>
          <Styled.ModalCaption>
            <Body as="span">
              The more you purchase, the larger your bonus becomes.
            </Body>{' '}
            <Tooltip
              position="bottom center"
              trigger={<BodyStrong>Learn about bonuses.</BodyStrong>}
            >
              <Styled.TooltipContents>
                <Caption>If you spend</Caption>
                <Caption>$0-49.99 you get no bonus;</Caption>
                <Caption>$50-99.99 you get 5% bonus;</Caption>
                <Caption>$100-249.99 you get 10% bonus;</Caption>
                <Caption>$250+ you get 15% bonus</Caption>
              </Styled.TooltipContents>
            </Tooltip>
          </Styled.ModalCaption>
          <Styled.CardContainer>
            {BUNDLE_AMOUNTS.map((bundle) => (
              <Styled.BundleCard
                key={bundle.id}
                selected={selectedBundle === bundle.id}
                title={bundle.amount ? `$${bundle.amount}` : 'Custom'}
                titleSize="md"
                onClick={() => setSelectedBundle(bundle.id)}
              >
                {bundle.amount ? (
                  <>
                    <Styled.BundleCaption $isCustom>
                      +${determineBonus(bundle.amount)} bonus
                    </Styled.BundleCaption>
                  </>
                ) : (
                  <Styled.BundleCaption $isCustom>
                    Enter amount you want to purchase
                  </Styled.BundleCaption>
                )}
              </Styled.BundleCard>
            ))}
          </Styled.CardContainer>
          <AccordionGroup open={selectedBundle === Bundle.CUSTOM}>
            <CurrencyInput
              label="Custom credit amount"
              maxValue={100000}
              value={amount}
              onChange={setAmount}
            />
          </AccordionGroup>
          {amounts && (
            <Styled.LineItems>
              <AccordionGroup open={!!amounts?.bonus}>
                <Styled.LineItem>
                  <Styled.ItemAmount>
                    <Styled.BundleCaption>You pay</Styled.BundleCaption>
                    <Styled.Amount>
                      {formatCurrency(amounts.credits, {
                        currencySymbol: '$',
                        precision: 0,
                      })}
                    </Styled.Amount>
                  </Styled.ItemAmount>
                </Styled.LineItem>
                <Styled.LineItem>
                  <Styled.ItemAmount>
                    <Styled.BundleCaption>Bonus</Styled.BundleCaption>
                    <Styled.Amount>
                      {formatCurrency(amounts.bonus, {
                        currencySymbol: '$',
                        precision: 0,
                      })}
                    </Styled.Amount>
                  </Styled.ItemAmount>
                </Styled.LineItem>
              </AccordionGroup>
              <Styled.Total $hasBonus={!!amounts?.bonus}>
                <Styled.BundleCaption>You get</Styled.BundleCaption>
                <Styled.Amount $isTotal>
                  {formatCurrency(amounts.credits + amounts.bonus, {
                    currencySymbol: '$',
                    precision: 0,
                  })}
                </Styled.Amount>
              </Styled.Total>
            </Styled.LineItems>
          )}
          <ModalButtons>
            <Button
              label="Close"
              variant="SECONDARY"
              onClick={() => choiceModalRef.current?.close()}
            />
            <Button
              disabled={!amounts}
              label={`Pay ${formatCurrency(amounts?.credits ?? 0, {
                currencySymbol: '$',
                precision: 0,
              })}`}
              loading={isOpeningCheckout}
              onClick={handleOpenCheckout}
            />
          </ModalButtons>
        </>
      )}
    </Modal>
  );
};

const UserBilling = ({ justify = 'space-between' }: UserBillingProps) => {
  const { balance, isLoadingBalance } = useBalance();

  return (
    <Styled.UserBillingWrapper $justify={justify}>
      <Styled.BalanceContainer>
        <Styled.Credit>
          <CreditIcon color="iconPrimaryInverse" size="sm" />
        </Styled.Credit>
        <Styled.Balance>
          {isLoadingBalance ? (
            <Skeleton width={30} />
          ) : balance?.last_balance ? (
            formatCurrency(balance?.last_balance)
          ) : undefined}
        </Styled.Balance>
      </Styled.BalanceContainer>
      <UserBillingModal
        trigger={
          <Button
            data-dd-action={getActionName('Billing', 'open', 'add credit')}
            label="Add"
            variant="BRAND"
            inverse
          />
        }
      />
    </Styled.UserBillingWrapper>
  );
};

export default ifBillingEnabled(UserBilling);
