import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { usePathname, useRouter } from '@polyai/common/hooks/useRouter';

import api from 'api';
import { AccountsResponse } from 'api/types';
import { setAccountId } from 'ducks/account/accountSlice';
import { useAppDispatch } from 'hooks/reduxHooks';

const reorderAccounts = (accounts: AccountsResponse) => {
  let reorderedAccounts = accounts;
  const accountPlatform = accounts.find((account) => account.id === 'PLATFORM');
  if (accountPlatform) {
    reorderedAccounts = [
      accountPlatform,
      ...reorderedAccounts.filter((account) => account.id !== 'PLATFORM'),
    ];
  }
  return reorderedAccounts;
};

const useAccounts = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();

  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const { replaceUrl } = useRouter();

  const { data: unorderedAccounts, isLoading: isLoadingAccounts } = useQuery(
    ['accounts'],
    api.getAccounts,
    {
      retry: false,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      onSuccess: (accounts) => {
        if (accounts.length && !accountId && pathname === '/') {
          const reorderedAccounts = reorderAccounts(accounts);
          replaceUrl(`/${reorderedAccounts[0].id}`);
        }
      },
    },
  );

  useEffect(() => {
    if (accountId) {
      dispatch(setAccountId(accountId));
    }
  }, [accountId, dispatch]);

  const accounts = useMemo(
    () => (unorderedAccounts ? reorderAccounts(unorderedAccounts) : []),
    [unorderedAccounts],
  );

  return { accounts, isLoadingAccounts };
};

export default useAccounts;
