import {
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import api from 'api';
import { HTTPError } from 'api/error';
import {
  FunctionCall,
  FunctionExecutionRequest,
  FunctionExecutionResponse,
  FunctionsDeployResponse,
  GetFunctionsResponse,
  PostFunction,
} from 'api/resources/functions/types';
import { setIsFunctionsDirty } from 'ducks/functions/functionsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useDraft } from 'hooks/useDraft';
import { useToast } from 'hooks/useToast';
import { getProjectQueryKey } from 'utils/query';

dayjs.extend(utc);

export const DEPLOY_FUNCTIONS_MUTATION_KEY = 'functions-deploy';

const updateFunctions = (
  func: FunctionCall,
  functions: FunctionCall[] = [],
  allowInactive: boolean = true,
) => {
  const funcs = [];
  if (func.active || allowInactive) {
    funcs.push(func);
  }
  funcs.push(...functions.filter((f) => f.function_id !== func.function_id));
  return funcs;
};

const useFunctionActions = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);
  const isFunctionsDirty = useAppSelector(
    (state) => state.functions.isFunctionsDirty,
  );

  const dispatch = useAppDispatch();
  const { onProjectUpdate } = useDraft();

  const functionsQueryKey = [accountId, projectId, 'functions'];
  const postFunctionMutationKey = [accountId, projectId, 'function'];

  const deployingFunctionsMutationKey = [
    accountId,
    projectId,
    DEPLOY_FUNCTIONS_MUTATION_KEY,
  ];

  const { mutate: deleteFunction, isLoading: isDeletingFunction } = useMutation<
    undefined,
    HTTPError,
    string,
    { previousFunctions: GetFunctionsResponse }
  >((id) => api.deleteFunction(id), {
    mutationKey: functionsQueryKey,
    onMutate: (func_id) => {
      const previousFunctions =
        queryClient.getQueryData<GetFunctionsResponse>(functionsQueryKey);
      queryClient.setQueryData(
        functionsQueryKey,
        (oldData: GetFunctionsResponse | undefined) => {
          if (!oldData) return;
          return {
            functions: oldData.functions.map((func) =>
              func.function_id !== func_id ? func : { ...func, active: false },
            ),
            active_functions: oldData.active_functions.filter(
              (func) => func.function_id !== func_id,
            ),
          };
        },
      );

      onProjectUpdate();

      if (!previousFunctions) return;

      return { previousFunctions };
    },
    onError: (e, variables, context) => {
      if (e.code === 'FunctionsDeploymentHasErrors') {
        queryClient.invalidateQueries(functionsQueryKey);
        toast.success({
          title: 'Successfully deleted function',
        });

        setTimeout(
          () =>
            toast.error({
              title: 'Failed to deploy functions',
            }),
          1000,
        );
      } else {
        toast.error({
          title: 'Delete function failed',
        });

        if (context?.previousFunctions) {
          queryClient.setQueryData(
            functionsQueryKey,
            context.previousFunctions,
          );
        } else {
          queryClient.refetchQueries(functionsQueryKey);
        }
      }
      queryClient.invalidateQueries(getProjectQueryKey(accountId, projectId));
    },
  });

  const {
    mutate: postFunction,
    mutateAsync: postFunctionAsync,
    isSuccess: isPostFunctionSuccess,
  } = useMutation<FunctionCall, HTTPError, PostFunction>(
    (func) => api.postFunction(func),
    {
      mutationKey: postFunctionMutationKey,
      onSuccess: (func) => {
        queryClient.setQueryData(
          functionsQueryKey,
          (state: GetFunctionsResponse | undefined) => {
            if (!state) {
              return state;
            }

            return {
              functions: updateFunctions(func, state?.functions),
              active_functions: updateFunctions(
                func,
                updateFunctions(func, state?.active_functions),
                false,
              ),
            };
          },
        );
        queryClient.setQueryData(
          [accountId, projectId, 'functions', func.function_id],
          func,
        );
        dispatch(setIsFunctionsDirty(true));
        onProjectUpdate();
      },
      onError: (e, variables) => {
        if (e.code === 'FunctionsDeploymentHasErrors') {
          queryClient.invalidateQueries([
            ...functionsQueryKey,
            variables.function_id,
          ]);
          toast.error({
            title: 'Failed to deploy functions',
          });
        } else {
          toast.error({
            title: `${
              variables.function_id ? 'Update' : 'Add'
            } function failed`,
          });
        }
      },
    },
  );

  const {
    mutate: duplicateFunction,
    mutateAsync: duplicateFunctionAsync,
    isLoading: isDuplicatingFunction,
  } = useMutation<FunctionCall, HTTPError, string>(
    (functionId) => api.duplicateFunction(functionId),
    {
      mutationKey: functionsQueryKey,
      onSuccess: (data) => {
        queryClient.setQueryData(
          functionsQueryKey,
          (
            {
              functions: oldFunctions,
              active_functions: oldActiveFunctions,
            }: GetFunctionsResponse | undefined = {
              functions: [],
              active_functions: [],
            },
          ) => ({
            functions: [data, ...oldFunctions],
            active_functions: [data, ...oldActiveFunctions],
          }),
        );

        toast.success({
          title: 'Successfully duplicated function',
        });
        onProjectUpdate();
      },
      onError: (e, variables) => {
        if (e.code === 'FunctionsDeploymentHasErrors') {
          queryClient.invalidateQueries([...functionsQueryKey, variables]);
          toast.success({
            title: 'Successfully duplicated function',
          });

          setTimeout(
            () =>
              toast.error({
                title: 'Failed to deploy functions',
              }),
            1000,
          );
        } else {
          toast.error({
            title: 'Duplicate function failed',
          });
        }
      },
    },
  );

  const {
    mutateAsync: executeFunction,
    data: functionExecution,
    isLoading: isLoadingExecuteFunction,
  } = useMutation<
    FunctionExecutionResponse,
    HTTPError,
    { functionId: string } & FunctionExecutionRequest
  >(
    ({ functionId, args }) =>
      api.executeFunction({
        accountId,
        projectId,
        functionId,
        body: { args },
      }),
    {
      onError: () => {
        toast.error({
          title: 'Failed to execute function test. Please try again later',
        });
      },
    },
  );

  const { mutate: deployFunctions } = useMutation<
    FunctionsDeployResponse,
    HTTPError
  >(
    () =>
      api.deployFunctions({
        accountId,
        projectId,
      }),
    {
      onSuccess: () => {
        dispatch(setIsFunctionsDirty(false));
      },
      onError: () => {
        toast.error({
          title: 'Failed to deploy functions',
        });
      },
      mutationKey: deployingFunctionsMutationKey,
    },
  );

  const isPostingFunction = !!useIsMutating(postFunctionMutationKey);
  const isDeployingFunctions = !!useIsMutating(deployingFunctionsMutationKey);

  return {
    deleteFunction,
    postFunction,
    postFunctionAsync,
    duplicateFunction,
    duplicateFunctionAsync,
    deployFunctions,
    executeFunction,
    isFunctionsDirty,
    isDeployingFunctions,
    isDuplicatingFunction,
    isDeletingFunction,
    isPostingFunction,
    isPostFunctionSuccess,
    isLoadingExecuteFunction,
    functionExecution,
  };
};

export default useFunctionActions;
