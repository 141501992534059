import { ReactElement, useCallback } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "../Icons";
import { Body } from "../Text";

import * as Styled from "./Callout.styled";

export enum CalloutType {
  INFO = "info",
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
}

export interface CalloutProps {
  headline?: string;
  type?: CalloutType;
  customIcon?: ReactElement;
  className?: string;
  isVisibleBorder?: boolean;
  isRoundedBorder?: boolean;
  hasCloseButton?: boolean;
  iconOnTop?: boolean;
  onCloseClick?: () => void;
}

const Callout: ComponentWithChildren<CalloutProps> = ({
  headline,
  children,
  type,
  customIcon,
  className,
  isRoundedBorder,
  isVisibleBorder = true,
  hasCloseButton,
  iconOnTop,
  onCloseClick,
}) => {
  const CalloutIcon = useCallback(() => {
    if (customIcon) {
      return customIcon;
    }

    switch (type) {
      case CalloutType.INFO:
        return <InfoIcon color="iconInfo" />;
      case CalloutType.ERROR:
        return <ErrorIcon color="iconDanger" />;
      case CalloutType.WARNING:
        return <WarningIcon color="iconWarning" />;
      case CalloutType.SUCCESS:
        return <SuccessIcon color="iconSuccess" />;
      default:
        return null;
    }
  }, [customIcon, type]);

  return (
    <Styled.CalloutWrapper
      $iconOnTop={iconOnTop}
      $isBorderVisible={isVisibleBorder}
      $isRoundedBorder={isRoundedBorder}
      $type={type}
      className={className}
    >
      {CalloutIcon && (
        <Styled.CalloutIconContainer>
          <CalloutIcon />
        </Styled.CalloutIconContainer>
      )}
      <Styled.CalloutContentWrapper>
        <Styled.CalloutContent>
          {headline && (
            <Styled.CalloutHeadline as="p">{headline}</Styled.CalloutHeadline>
          )}
          <Body>{children}</Body>
        </Styled.CalloutContent>
        {hasCloseButton && <Styled.CloseButton onClick={onCloseClick} />}
      </Styled.CalloutContentWrapper>
    </Styled.CalloutWrapper>
  );
};

export default Callout;
