import { CaptionStrong } from "components/atoms/Text";
import { Tooltip } from "components/atoms/Tooltip";

import * as Styled from "./InputError.styled";

type InputErrorProps = {
  errors?: string[];
  showErrorAsTooltip?: boolean;
};

export const InputError = ({
  errors = [],
  showErrorAsTooltip,
}: InputErrorProps) => {
  if (!errors?.length) {
    return null;
  }

  if (showErrorAsTooltip) {
    return (
      <Styled.TooltipWrapper>
        <Tooltip
          position="bottom center"
          trigger={
            <Styled.TooltipIcon data-test-id="input-error-icon" customFill />
          }
        >
          <CaptionStrong data-test-id="input-error" role="alert">
            {errors.join(", ")}
          </CaptionStrong>
        </Tooltip>
      </Styled.TooltipWrapper>
    );
  }

  return (
    <Styled.ErrorCaption data-test-id="input-error" role="alert">
      {errors.join(", ")}
    </Styled.ErrorCaption>
  );
};

export default InputError;
