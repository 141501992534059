import * as accounts from './resources/accounts';
import * as analytics from './resources/analytics';
import * as balance from './resources/balance';
import * as chat from './resources/chat';
import * as config from './resources/config';
import * as conversation from './resources/conversation';
import * as deployments from './resources/deployments';
import * as features from './resources/features';
import * as flows from './resources/flows';
import * as functions from './resources/functions';
import * as handoff from './resources/handoff';
import * as knowledgeBase from './resources/knowledgeBase';
import * as language from './resources/language';
import * as payments from './resources/payments';
import * as phoneNumber from './resources/phoneNumber';
import * as projects from './resources/projects';
import * as pronunciations from './resources/pronunciations';
import * as questionnaire from './resources/questionnaire';
import * as sampleProjects from './resources/sampleProjects';
import * as secretsManager from './resources/secretsManager';
import * as smsCredentials from './resources/smsCredentials';
import * as smsTemplates from './resources/smsTemplates';
import * as telephony from './resources/telephony';
import * as topics from './resources/topics';
import * as userManagement from './resources/userManagement';
import * as voiceTuningSettings from './resources/voiceTuningSettings';
export * as types from './types';

export const api = {
  ...accounts,
  ...analytics,
  ...balance,
  ...chat,
  ...config,
  ...conversation,
  ...features,
  ...flows,
  ...functions,
  ...handoff,
  ...knowledgeBase,
  ...language,
  ...payments,
  ...phoneNumber,
  ...projects,
  ...questionnaire,
  ...sampleProjects,
  ...secretsManager,
  ...smsCredentials,
  ...smsTemplates,
  ...telephony,
  ...topics,
  ...userManagement,
  ...voiceTuningSettings,
  ...deployments,
  ...pronunciations,
};

export default api;
