import { ReactElement } from 'react';

export enum UploadType {
  TOPICS = 'topic',
}

export const text = {
  [UploadType.TOPICS]: {
    title: 'Import data',
    choose_file_title: 'Upload file',
    choose_file_body: (renderCta: (ctaText: string) => ReactElement) => (
      <>
        Import a CSV to add or replace topics of the current Knowledge base.
        Existing content on the page will be overwritten. You can download a
        template for the CSV format {renderCta('here.')}
      </>
    ),
    choose_file_button_text: 'Select file',
    replace_file_button_text: 'Replace file',
  },
};
