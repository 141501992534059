import styled, { css } from "styled-components";

import { Body, BodyStrong } from "components/atoms/Text";

import themeSpacing from "styles/config/spacing";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: ${themeSpacing.sm1};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${themeSpacing.xs3};
`;

export const ModalTitle = styled(BodyStrong)`
  font-size: ${({ theme }) => theme.text.size.body.body2};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
`;

export const ModalContent = styled(Body)`
  margin-bottom: ${themeSpacing.sm3};
  white-space: pre-line;
`;

export const Actions = styled.div<{ $hasCancelButton?: boolean }>`
  text-align: right;

  ${({ $hasCancelButton }) =>
    $hasCancelButton &&
    css`
      > button:first-child {
        margin-right: ${themeSpacing.xs3};
      }
    `}
`;
