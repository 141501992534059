import { PROJECT_RESOURCE } from 'hooks/useProjectResources';

type Empty = {};

/**
 * A mapping of all Jupiter API error codes to the error's metadata type
 *
 * This should be kept up to date with the API
 **/
type APIErrorData = {
  UNASSIGNED: Empty;
  SchemaValidationFailed: Empty;
  UserAccessForbidden: { action: 'READ' | 'WRITE'; resource: PROJECT_RESOURCE };
  ExternalUserForbidden: Empty;
  NonSuperUserForbidden: Empty;

  AccountsUnknownError: Empty;
  AccountsNotFound: Empty;
  AccountNotFound: Empty;
  AccountInsufficientPermissions: Empty;
  AccountCreateInvalidID: Empty;
  AccountCreateInvalidIDSuffix: Empty;
  AccountCreateExistingID: Empty;
  AccountCreateUserPermissionsNotCreated: Empty;
  AccountCreateMeteringNotCreated: Empty;

  AnalyticsUnknownError: Empty;
  AnalyticsLuzmoException: Empty;
  AnalyticsLuzmoIntegrationNotFound: Empty;

  AuthUnknownError: Empty;
  AuthUserNotFound: Empty;
  AuthUserUnauthorised: Empty;
  AuthUserInvalidToken: Empty;
  AuthGetUserFailed: Empty;
  AuthUserIDNotFound: Empty;
  AuthGetUserIDFailed: Empty;

  ChatUnknownError: Empty;
  ChatDraftNotExist: Empty;
  ChatTimeout: Empty;
  ChatDraftDeploymentFailed: Empty;
  ChatInvalidArgument: { details: string };

  ConversationsUnknownError: Empty;

  DeploymentsUnknownError: Empty;
  DeploymentNotFound: Empty;
  DeploymentsEnvironmentAlreadyPublished: Empty;
  DeploymentsUpdateInvalidClientEnv: Empty;
  DeploymentsGetMissingClientEnv: Empty;
  DeploymentsInvalidTargetEnvironment: Empty;

  FeaturesUnknownError: Empty;
  FeatureNotFound: Empty;

  FlowsUnknownError: Empty;
  FlowNotFound: Empty;
  FlowsInvalidFunctionType: Empty;
  FlowsNameAlreadyExists: Empty;
  FlowsFunctionNameAlreadyExists: Empty;
  FlowsReservedParameterName: { parameter: string };
  FlowsReservedFunctionName: Empty;
  FlowsGetDefaultFlowFailed: Empty;

  FunctionsUnknownError: Empty;
  FunctionNotFound: Empty;
  FunctionBaseCodeNotFound: Empty;
  FunctionGetBaseCodeFromS3Failed: Empty;
  FunctionsDeploymentHasErrors: { function_id: string; name: string };
  FunctionsNoneToDeploy: Empty;
  FunctionExecutionFailed: Empty;
  FunctionFailedToParse: Empty;
  FunctionsInvalidType: Empty;
  FunctionNameAlreadyExists: Empty;
  FunctionsReservedName: Empty;
  FunctionsReservedParameterName: { parameter: string };

  HandoffsUnknownError: Empty;
  HandoffNotFound: Empty;

  KnowledgeBaseUnknownError: Empty;
  KnowledgeBaseTopicAlreadyExists: Empty;
  KnowledgeBaseImportNoCSVFound: Empty;
  KnowledgeBaseImportInvalidType: Empty;
  KnowledgeBaseImportMissingColumns: {
    columns: string[];
    missing_headers: string[];
  };

  MeteringUnknownError: Empty;

  PaymentsUnknownError: Empty;

  PhoneNumbersUnknownError: Empty;
  PhoneNumbersClientEnvAlreadyOwnsNumber: Empty;

  ProjectsUnknownError: Empty;
  ProjectNotFound: Empty;

  QuestionnaireUnknownError: Empty;

  RecordingsUnknownError: Empty;
  RecordingNotFound: Empty;

  ResourcesUnknownError: Empty;
  ResourceNotFound: Empty;

  SettingsUnknownError: Empty;
  SettingsNotFound: Empty;
  SettingsResourceNotFound: Empty;

  SMSUnknownError: Empty;
  SMSTemplateNotFound: Empty;

  SecretsUnknownError: Empty;

  TelephonyUnknownError: Empty;

  VoiceUnknownError: Empty;
};

export type APIErrorCode = keyof APIErrorData;

export type HTTPErrorResponse<T extends {} = APIErrorData['UNASSIGNED']> = {
  error_code: APIErrorCode;
  error_id: string;
  error_message: string;
  success: boolean;
  data: T;
};

export class HTTPError<T extends APIErrorCode = APIErrorCode> extends Error {
  httpStatus: number;
  id: string;
  code: T;
  private _data: APIErrorData[T];

  constructor(
    name: string,
    message: string,
    httpStatus: number,
    code: T,
    id: string,
    data: APIErrorData[T],
  ) {
    super(message);
    this.cause = code;

    this.name = name;
    this.httpStatus = httpStatus;
    this.code = code;
    this.id = id;
    this._data = data;
  }

  /**
   * Returns the underlying error data cast to the associated error's data type.
   *
   * @param {K} code - the error code
   * @returns a strongly-typed error data object
   */

  data<K extends T>(code: K): APIErrorData[K] {
    return this._data as APIErrorData[typeof code];
  }
}
