import { ClientEnvironment } from '@polyai/common/constants/global.constants';

export const DRAFT_ENVIRONMENT = 'draft';

// When Env v2 work completed this can extend ClientEnvironment
export enum ClientEnv {
  DRAFT = DRAFT_ENVIRONMENT,
  SANDBOX = ClientEnvironment.SANDBOX,
  PRE_RELEASE = ClientEnvironment.PRE_RELEASE,
  LIVE = ClientEnvironment.LIVE,
}
export const environmentOptions: Record<ClientEnv, string> = {
  draft: 'Draft',
  sandbox: 'Sandbox',
  'pre-release': 'Pre-release',
  live: 'Live',
};
