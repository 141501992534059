import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'api';
import { HTTPError } from 'api/error';
import { FeaturesResponse } from 'api/resources/features';
import { useToast } from '../useToast';
import { useIsFeatureOn } from './useIsFeatureOn';

const DEFAULT_FEATURES = [] as FeaturesResponse;

export const useFeatureSwitch = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isFeatureOn, features, isLoadingFeatures, didFeaturesError } =
    useIsFeatureOn();

  const { mutate: createFeature, isLoading: isLoadingCreate } = useMutation(
    api.createFeature,
    {
      retry: false,
      onSuccess: (features) => {
        queryClient.setQueryData(['features'], features);
        queryClient.invalidateQueries(['enabledFeature']);
      },
      onError: (error: HTTPError, { name }) => {
        let message = error.message;
        if (features?.some((feature) => feature.name === name)) {
          message = `Feature named "${name}" already exists.`;
        }
        toast.error({
          title: 'Failed to create new feature',
          description: message,
        });
      },
    },
  );

  const { mutate: updateFeature, isLoading: isLoadingUpdate } = useMutation(
    api.updateFeature,
    {
      retry: false,
      onSuccess: (features) => {
        queryClient.setQueryData(['features'], features);
        queryClient.invalidateQueries(['enabledFeature']);
      },
    },
  );

  return {
    features: features ?? DEFAULT_FEATURES,
    isLoadingFeatures,
    didFeaturesError,
    createFeature,
    updateFeature,
    isFeatureOn,
    isLoadingCreate,
    isLoadingUpdate,
  };
};
