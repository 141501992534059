import { useCallback } from 'react';

import { hasFeatureAccess } from 'lib/featuresHelper';
import { PROJECT_RESOURCE } from './constants';
import { useProjectResources } from './useProjectResources';
import { useUserAccess } from './useUserAccess';

const isInternalResource = (resource: PROJECT_RESOURCE) =>
  resource === PROJECT_RESOURCE.FLOWS;

const checkInternalOnlyResource = (
  resource: PROJECT_RESOURCE,
  isInternalUser: boolean,
) => !isInternalResource(resource) || isInternalUser;

export const useProjectResource = () => {
  const { isInternalUser } = useUserAccess();
  const { features, restrictedFeatures } = useProjectResources();

  const isProjectResourceEnabled = useCallback(
    (resource: PROJECT_RESOURCE) =>
      hasFeatureAccess(resource, features, restrictedFeatures) &&
      checkInternalOnlyResource(resource, isInternalUser),
    [features, isInternalUser, restrictedFeatures],
  );

  return {
    isProjectResourceEnabled,
  };
};
