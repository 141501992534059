import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import ENDPOINTS from 'api/endpoints';
import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  ActiveDeploymentsResponse,
  ArtifactDeployment,
  DeploymentsResponse,
} from './types';

export const getDeployments = (clientEnv: ClientEnvironment) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<DeploymentsResponse>(
    generateNextPath(ENDPOINTS.DEPLOYMENTS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
      params: {
        client_env: clientEnv,
      },
    },
  );
};

export const promoteDeployment = (
  deploymentId: string,
  deploymentMessage: string,
) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;
  return doPost<ArtifactDeployment>(
    generateNextPath(ENDPOINTS.PROMOTE_DEPLOYMENT, {
      accountId,
      projectId,
      deploymentId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      deployment_message: deploymentMessage,
    },
    { version: 'v2' },
  );
};

export const rollbackDeployment = (
  deploymentId: string,
  deploymentMessage: string,
) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;
  return doPost<ArtifactDeployment>(
    generateNextPath(ENDPOINTS.ROLLBACK_DEPLOYMENT, {
      accountId,
      projectId,
      deploymentId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      deployment_message: deploymentMessage,
    },
    { version: 'v2' },
  );
};

export const getActiveDeployments = () => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<ActiveDeploymentsResponse>(
    generateNextPath(ENDPOINTS.ACTIVE_DEPLOYMENTS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};
