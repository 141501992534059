import hexToRgb from "styles/functions/hexToRgb";
import baseColors from "./baseColors";

export const systemColors = {
  errorText01: baseColors.red["50"],

  informationText01: baseColors.blue["60"],

  standard: baseColors.purpleGrey["50"],
  shadow: hexToRgb(baseColors.purpleGrey["100"], 0.1),
};

const defaultThemeColors = {
  /** BACKGROUND **/
  background: baseColors.white,
  backgroundPage: baseColors.purpleGrey["05"],
  backgroundPrimary: baseColors.brand["50"],
  backgroundPrimaryHover: baseColors.brand["70"],
  backgroundPrimaryInverse: baseColors.brand["50"],
  backgroundPrimaryInverseHover: baseColors.brand["30"],
  backgroundBrand: baseColors.brand["50"],
  backgroundBrandInverse: baseColors.brand["20"],
  backgroundBrandHover: baseColors.brand["70"],
  backgroundAccent: baseColors.grey["05"],
  backgroundAccentInverse: baseColors.purpleGrey["90"],
  backgroundInverse: baseColors.purpleGrey["100"],
  backgroundInverseHover: baseColors.purpleGrey["90"],
  backgroundInverseDisabled: baseColors.grey["90"],
  backgroundInverseSkeleton: baseColors.grey["90"],

  backgroundHover: baseColors.offWhite,
  backgroundDisabled: baseColors.purpleGrey["20"],
  backgroundSkeleton: baseColors.purpleGrey["20"],
  backgroundDanger: baseColors.red["50"],
  backgroundDangerHover: baseColors.red["90"],
  backgroundSuccess: baseColors.green["50"],
  backgroundSuccessHover: baseColors.green["90"],
  backgroundWarning: baseColors.yellow["50"],
  backgroundInformation: baseColors.blue["90"],
  backgroundOverlay: hexToRgb("#222233", 0.8),

  /** BORDER **/
  borderPrimary: baseColors.brand["50"],
  borderBrand: baseColors.brand["50"],
  borderBrandHover: baseColors.brand["50"],
  borderBrandActive: baseColors.brand["50"],
  borderBrandInverse: baseColors.brand["50"],
  borderBrandInverseHover: baseColors.brand["50"],
  borderBrandInverseActive: baseColors.brand["50"],
  borderSubtle: baseColors.purpleGrey["10"],
  borderSubtleInverse: baseColors.purpleGrey["90"],
  borderStrong: baseColors.purpleGrey["20"],
  borderStrongInverse: baseColors.purpleGrey["80"],
  borderUnderline: baseColors.grey["40"],
  borderUnderlineInverse: baseColors.grey["50"],

  borderFocus: baseColors.brand["80"],
  borderInverseFocus: baseColors.purpleGrey["20"],
  borderDisabled: baseColors.purpleGrey["20"],
  borderDanger: baseColors.red["50"],
  borderSuccess: baseColors.green["50"],
  borderWarning: baseColors.yellow["50"],
  borderInfo: baseColors.blue["50"],

  /** ICON **/
  iconPrimary: baseColors.purpleGrey["100"],
  iconPrimaryHover: baseColors.purpleGrey["20"],
  iconPrimaryBrand: baseColors.brand["50"],
  iconPrimaryInverse: baseColors.white,
  iconSecondary: baseColors.grey["70"],
  iconSecondaryInverse: baseColors.grey["40"],
  iconBrand: baseColors.brand["50"],
  iconBrandInverse: baseColors.brand["50"],
  iconAccent: baseColors.brand["50"],
  iconButtonBrand: baseColors.white,

  iconDisabled: baseColors.grey["50"],
  iconInverseDisabled: baseColors.grey["70"],
  iconDanger: baseColors.red["50"],
  iconSuccess: baseColors.green["50"],
  iconWarning: baseColors.yellow["50"],
  iconInfo: baseColors.blue["50"],

  /** TEXT **/
  textPrimary: baseColors.purpleGrey["100"],
  textPrimaryInverse: baseColors.white,
  textSecondary: baseColors.grey["70"],
  textSecondaryInverse: baseColors.grey["40"],
  textButtonBrand: baseColors.white,
  textHelper: baseColors.grey["50"],
  textHelperInverse: baseColors.grey["70"],
  textPlaceholder: baseColors.grey["50"],
  textPlaceholderInverse: baseColors.grey["70"],
  textBrand: baseColors.brand["50"],
  textBrandInverse: baseColors.brand["20"],
  textAccent: baseColors.brand["50"],

  textDisabled: baseColors.grey["50"],
  textInverseDisabled: baseColors.grey["70"],
  textDanger: baseColors.red["50"],
  textSuccess: baseColors.green["70"],
  textWarning: baseColors.yellow["90"],
  textInfo: baseColors.blue["50"],
};

export const nodeColors = {
  startNode: "#42428a",
  scriptingNode: "#b37196",
  interactiveNode: "#594d6b",
  informationNode: "#9d6fb8",
  transferNode: "#c87273",
  integrationNode: "#de7450",
  DTMFNode: "#f4762d",
  endNode: "#ffbc52",
};

export const defaultColors = {
  ...systemColors,
  ...defaultThemeColors,
  ...nodeColors,
};

export type ColorMapping = {
  [key in keyof typeof defaultColors]: string;
};

export type ColorScheme = {
  colors: ColorMapping;
  type: "light" | "dark";
};

const defaultTheme: ColorScheme = {
  colors: defaultColors,
  type: "light",
};

export default defaultTheme;
