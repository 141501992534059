import ReactPopup from "reactjs-popup";
import styled from "styled-components";

import { Body } from "components/atoms/Text";

import breakpoints, { under } from "styles/config/breakpoints";
import themeColors from "styles/config/colors";
import sizes from "styles/config/sizes";
import themeSpacing from "styles/config/spacing";

export enum ModalSize {
  sm = "small",
  md = "medium",
  lg = "large",
  xl = "extra-large",
}

const ModalWidths = {
  [ModalSize.sm]: sizes.modalWidthSmall,
  [ModalSize.md]: sizes.modalWidthMedium,
  [ModalSize.lg]: sizes.modalWidthLarge,
  [ModalSize.xl]: sizes.modalWidthExtraLarge,
};

export const Popup = styled(ReactPopup)<{ $size: ModalSize }>`
  &-overlay {
    overflow-y: scroll;
    overscroll-behavior: none;
    padding: ${themeSpacing.md2} ${themeSpacing.sm1};
    background: ${themeColors.backgroundOverlay};

    @media ${under(breakpoints.sm)} {
      padding: ${themeSpacing.sm1};
    }
  }

  &-content {
    box-sizing: border-box;
    width: ${({ $size }) => ModalWidths[$size]};
    max-width: 100%;
    padding: ${themeSpacing.sm4};

    opacity: 1;
    background: ${themeColors.background};
    border: 1px solid ${themeColors.borderSubtleInverse};
    border-radius: ${themeSpacing.radiusMedium};
    box-shadow: 0 1px 10px ${themeColors.shadow};
  }
`;

export const ModalBody = styled(Body)`
  margin: ${themeSpacing.sm1} 0;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${themeSpacing.sm1};
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
  margin-left: auto;
`;
