export const getDialectOfLanguage = (languageCode: string) => {
  const dialect = new Intl.DisplayNames('en', {
    type: 'language',
    languageDisplay: 'dialect',
  });
  const languageDialect = dialect.of(languageCode)?.split(' ');
  return languageDialect
    ? `${languageDialect[1]}-${languageDialect[0]}`
    : languageCode;
};

export const getLanguageName = (languageCode: string) => {
  const languageNames = new Intl.DisplayNames(['en'], {
    type: 'language',
    languageDisplay: 'standard',
  });
  return languageNames.of(languageCode);
};
