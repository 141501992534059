export const PATHS = {
  // Account
  ASSISTANTS: '/:accountId?',
  BILLING: '/:accountId?/billing',
  SECRETS: '/:accountId?/secrets',
  USERS: '/:accountId?/users',

  // Assistant
  CREATE_ASSISTANT: '/:accountId/create-assistant',
  VOICE: '/:accountId/:projectId/voice',
  ANALYTICS_OVERVIEW: '/:accountId/:projectId/overview',
  ANALYTICS_SAFETY: '/:accountId/:projectId/assistant-safety',
  ASSISTANT_FUNCTIONS: '/:accountId/:projectId/functions',
  ASSISTANT_FUNCTION: '/:accountId/:projectId/functions/:functionId',
  ASSISTANT_FUNCTION_CREATE: '/:accountId/:projectId/functions/create',
  CONVERSATION_REVIEW: '/:accountId/:projectId/conversations/:conversationId',
  CONVERSATIONS: '/:accountId/:projectId/conversations',
  HANDOFF: '/:accountId/:projectId/handoff',
  KNOWLEDGE_BASE: '/:accountId/:projectId/knowledge-base',
  RULES: '/:accountId/:projectId/rules',
  ABOUT: '/:accountId/:projectId/about',
  SAMPLE_PROJECT: '/:accountId/template-project/:projectId',
  ENVIRONMENTS: '/:accountId/:projectId/environments',
  SETTINGS: '/:accountId/:projectId/settings',
  SMS_TEMPLATES: '/:accountId/:projectId/sms-templates',
  USER_SETTINGS: '/settings/user',
  FLOWS: '/:accountId/:projectId/flows',
  FLOW_DETAILS: '/:accountId/:projectId/flows/:flowId',
} as const;

export const PAGE_TITLES: { [key in keyof typeof PATHS]: string } = {
  ASSISTANTS: 'Assistants',
  BILLING: 'Billing',
  SECRETS: 'Secrets',
  USERS: 'Users',

  CREATE_ASSISTANT: 'Create new assistant',
  VOICE: 'Voice',
  ANALYTICS_OVERVIEW: 'Overview',
  ANALYTICS_SAFETY: 'Assistant Safety',
  ASSISTANT_FUNCTIONS: 'Functions',
  ASSISTANT_FUNCTION: 'Function',
  ASSISTANT_FUNCTION_CREATE: 'Function',
  CONVERSATION_REVIEW: 'Conversation',
  CONVERSATIONS: 'Conversations',
  HANDOFF: 'Handoff',
  KNOWLEDGE_BASE: 'Knowledge Base',
  RULES: 'Rules',
  ABOUT: 'About',
  SAMPLE_PROJECT: 'Template Project',
  ENVIRONMENTS: 'Environments & Versions',
  SETTINGS: 'Assistant Settings',
  SMS_TEMPLATES: 'SMS messaging',
  USER_SETTINGS: 'User Settings',
  FLOWS: 'Flows',
  FLOW_DETAILS: 'Flow details',
};

export const BASE_ROUTE = '/:accountId/:projectId/';
