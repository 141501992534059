import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import api from 'api';
import { HTTPError } from 'api/error';
import { ArtifactDeployment } from 'api/resources/deployments/types';
import { useAppSelector } from 'hooks/reduxHooks';
import { useToast } from 'hooks/useToast';

type UseDeploymentsProps = {
  clientEnv: ClientEnvironment;
};

export const DEPLOYMENTS_MUTATION_KEY = 'deployments';

export const useDeployments = ({ clientEnv }: UseDeploymentsProps) => {
  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);
  const queryKey = [accountId, projectId, 'deployments', clientEnv];
  const activeDeploymentsQueryKey = [
    accountId,
    projectId,
    'deployments',
    'active',
  ];
  const queryClient = useQueryClient();
  const toast = useToast();

  const prefetchDeployments = useCallback(
    (clientEnv: ClientEnvironment) => {
      const queryKey = [accountId, projectId, 'deployments', clientEnv];
      const currentQuery = queryClient.getQueryData(queryKey);

      if (currentQuery) {
        return Promise.resolve();
      }

      return queryClient.prefetchQuery({
        queryKey,
        queryFn: () => api.getDeployments(clientEnv),
      });
    },
    [accountId, projectId, queryClient],
  );

  const { data: deployments, isLoading: isLoadingDeployments } = useQuery({
    queryKey: queryKey,
    queryFn: () => api.getDeployments(clientEnv),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: activeDeployments } = useQuery({
    queryKey: activeDeploymentsQueryKey,
    queryFn: () => api.getActiveDeployments(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: promoteDeployment, isLoading: isPromotingDeployment } =
    useMutation<
      ArtifactDeployment,
      HTTPError,
      {
        deploymentId: string;
        newClientEnv: ClientEnvironment;
        deploymentMessage: string;
      }
    >(
      ({ deploymentId, deploymentMessage }) => {
        return api.promoteDeployment(deploymentId, deploymentMessage);
      },
      {
        mutationKey: [DEPLOYMENTS_MUTATION_KEY],
        onSuccess: (data: ArtifactDeployment) => {
          toast.success({
            title: `Promoted to ${data.client_env}`,
          });

          queryClient.invalidateQueries([
            accountId,
            projectId,
            'deployments',
            data.client_env,
          ]);
          queryClient.invalidateQueries(activeDeploymentsQueryKey);
        },
        onError: (_, { newClientEnv }) => {
          toast.error({
            title: `Failed to promote to ${newClientEnv}`,
          });
        },
      },
    );

  const { mutate: rollbackDeployment, isLoading: isRollingBackDeployment } =
    useMutation<
      ArtifactDeployment,
      HTTPError,
      {
        deploymentId: string;
        deploymentMessage: string;
      }
    >(
      ({ deploymentId, deploymentMessage }) => {
        return api.rollbackDeployment(deploymentId, deploymentMessage);
      },
      {
        mutationKey: [DEPLOYMENTS_MUTATION_KEY],
        onSuccess: () => {
          toast.success({
            title: 'Successfully rolled back',
          });

          queryClient.invalidateQueries(queryKey);
          queryClient.invalidateQueries(activeDeploymentsQueryKey);
          queryClient.invalidateQueries([accountId, projectId]);
        },
        onError: () => {
          toast.error({
            title: 'Failed to roll back',
          });
        },
      },
    );

  return {
    deployments: deployments?.deployments ?? [],
    isLoadingDeployments,
    promoteDeployment,
    isPromotingDeployment,
    rollbackDeployment,
    isRollingBackDeployment,
    activeDeployments,
    prefetchDeployments,
  };
};
