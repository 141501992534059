import { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Howl } from 'howler';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { CompoundType } from '@polyai/common/types/helpers';

import api from 'api';
import {
  AudioGenerationConfig,
  AudioGenerationResponse,
} from 'api/resources/voiceTuningSettings/types';
import { TuningSetting } from 'api/types';
import { useDraft } from 'hooks/useDraft';
import { useToast } from 'hooks/useToast';

export const VOICE_TUNING_MUTATION_KEY = 'voice_tuning_settings';
type GeneratedAudioSettings = CompoundType<
  TuningSetting & {
    text: string;
  }
>;

const useVoiceTuningSettings = () => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { onProjectUpdate } = useDraft();

  const queryKey = [accountId, projectId, VOICE_TUNING_MUTATION_KEY];
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [audioFile, setAudioFile] = useState<Howl | undefined>(undefined);

  const [generatedAudioSettings, setGeneratedAudioSettings] = useState<
    GeneratedAudioSettings | undefined
  >(undefined);

  const loadAudio = (url: string) => {
    const audioFile = new Howl({
      src: [url],
      format: 'wav',
      xhr: {
        method: 'GET',
        withCredentials: false,
      },
      autoplay: true,
      onplay: () => setIsAudioPlaying(true),
      onend: () => setIsAudioPlaying(false),
    });
    setAudioFile(audioFile);
    if (audioFile) {
      setIsAudioPlaying(true);
      audioFile.play();
    }
    return audioFile;
  };

  const handlePlay = () => {
    setIsAudioPlaying(true);
    audioFile?.play();
  };

  const handleStop = () => {
    audioFile?.stop();
    audioFile?.seek(0);
    setIsAudioPlaying(false);
  };

  const resetAudio = useCallback(() => {
    setIsAudioPlaying(false);
    setAudioFile(undefined);
    setGeneratedAudioSettings(undefined);
  }, []);

  const { mutate: editVoiceTuningSettings } = useMutation({
    mutationKey: queryKey,
    mutationFn: ({
      voiceId,
      settings,
    }: {
      voiceId: string;
      settings: TuningSetting;
    }) => api.editVoiceTuningSettings({ voiceId, settings }),
    onSuccess: (settings: TuningSetting) => {
      toast.info({
        title: 'Publish to apply changes',
      });
      queryClient.setQueryData(queryKey, settings);
      queryClient.invalidateQueries([accountId, projectId, 'voices']);
      onProjectUpdate();
    },
    onError: () => {
      toast.error({
        title: 'Voice could not be updated',
      });
    },
  });

  const { mutate: generateAudio, isLoading: isGeneratingAudio } = useMutation({
    mutationKey: queryKey,
    mutationFn: ({
      text,
      language,
      config,
    }: {
      text: string;
      language: string;
      config: AudioGenerationConfig;
    }) => api.generateAudio({ text, language, config }),
    onSuccess: (response: AudioGenerationResponse, { config }) => {
      loadAudio(response.url);
      const { stability, similarity_boost, style, optimize_streaming_latency } =
        config;
      setGeneratedAudioSettings({
        text: response.text,
        stability,
        similarity_boost,
        style,
        optimize_streaming_latency,
      });
    },
    onError: () => {
      toast.error({
        title: 'Voice could not be generated',
      });

      setGeneratedAudioSettings(undefined);
    },
  });

  return {
    editVoiceTuningSettings,
    generateAudio,
    isGeneratingAudio,
    playAudioFile: handlePlay,
    stopAudioFile: handleStop,
    isAudioPlaying,
    audioFile,
    resetAudio,
    generatedAudioSettings,
  };
};

export default useVoiceTuningSettings;
