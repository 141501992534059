import { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { LuzmoAuthToken } from '@polyai/common/api/resources/luzmo/types';

import api from 'api';
import { HTTPError } from 'api/error';
import useAccount from 'hooks/useAccount';
import { useUserAccess } from 'hooks/useProjectResources';
import { useAppSelector } from '../reduxHooks';

type UseLuzmoAuthProps = {
  cssOverride: string;
};

export const ANALYTICS_MUTATION_KEY = 'analytics';

const useLuzmoAuth = ({ cssOverride }: UseLuzmoAuthProps) => {
  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);

  const { isInternalUser } = useUserAccess();

  const { account } = useAccount();

  const [token, setToken] = useState<LuzmoAuthToken>();

  const {
    mutate: getToken,
    isLoading: isTokenLoading,
    isSuccess: isTokenSuccess,
  } = useMutation<LuzmoAuthToken, HTTPError, { suborganisation: string }>(
    [accountId, projectId, ANALYTICS_MUTATION_KEY, 'token'],
    ({ suborganisation }) =>
      api.getLuzmoAuthToken(
        suborganisation ? suborganisation : 'PolyAI',
        undefined,
        cssOverride,
      ),
    {
      retry: false,
      onSuccess: (data) => setToken(data),
    },
  );

  useEffect(() => {
    if (account) {
      const suborganisation = isInternalUser ? 'PolyAI' : account.name;
      getToken({ suborganisation });
    }
  }, [account, isInternalUser, getToken]);

  return {
    token,
    isTokenLoading,
    isTokenSuccess,
  };
};

export default useLuzmoAuth;
