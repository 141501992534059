import { pathToRegexp } from 'path-to-regexp';
import { generatePath } from 'react-router-dom';

import api from 'api';
import { PROJECT_RESOURCE } from 'hooks/useProjectResources';
import { hasFeatureAccess } from 'lib/featuresHelper';
import { BASE_ROUTE, PATHS } from 'routes/routes.constants';

const PROJECT_RESOURCE_PATH_MAP: {
  [key in PROJECT_RESOURCE]?: (typeof PATHS)[keyof typeof PATHS];
} = {
  // BUILD
  [PROJECT_RESOURCE.KNOWLEDGE_BASE]: PATHS.KNOWLEDGE_BASE,
  [PROJECT_RESOURCE.VOICE]: PATHS.VOICE,
  [PROJECT_RESOURCE.ABOUT]: PATHS.ABOUT,
  [PROJECT_RESOURCE.RULES]: PATHS.RULES,
  [PROJECT_RESOURCE.FUNCTIONS]: PATHS.ASSISTANT_FUNCTIONS,
  [PROJECT_RESOURCE.HANDOFFS]: PATHS.HANDOFF,
  [PROJECT_RESOURCE.SMS]: PATHS.SMS_TEMPLATES,

  // ANALYZE
  [PROJECT_RESOURCE.ANALYTICS]: PATHS.ANALYTICS_OVERVIEW,
  [PROJECT_RESOURCE.CONVERSATIONS]: PATHS.CONVERSATIONS,

  // CONFIGURE
  [PROJECT_RESOURCE.SETTINGS]: PATHS.SETTINGS,
  [PROJECT_RESOURCE.ENVIRONMENTS]: PATHS.ENVIRONMENTS,
};

export const matchNextPath = (
  currentUrl: string,
  expectedTemplate: string,
  exact: boolean = true,
) => {
  const regexp = pathToRegexp(
    expectedTemplate.replace(/\[\w+\]/g, (match) => `:${match.slice(1, -1)}`),
    undefined,
    { start: exact, end: exact },
  );

  return regexp.test(currentUrl.split('?')[0]);
};

export const generateNextPath = <Path extends string>(
  template: Path,
  urlParams?: Parameters<typeof generatePath<Path>>[1],
  queryParams?: { [key: string]: string | undefined },
) => {
  const filteredQuery = queryParams
    ? Object.keys(queryParams).reduce(
        (filt, key) =>
          queryParams[key] ? { ...filt, [key]: queryParams[key]! } : filt,
        {} as { [key: string]: string },
      )
    : {};

  if (!Object.keys(filteredQuery).length) {
    return generatePath(template, urlParams);
  }
  const query = queryParams ? new URLSearchParams(filteredQuery) : undefined;

  return `${generatePath(template, urlParams)}?${query?.toString()}`;
};

export const generateProjectLandingResource = async (
  accountId: string,
  projectId: string,
) => {
  const data = await api.getEnabledFeatures(accountId, projectId);

  // if no resource found (for which project wouldn't be visible in the first place),
  // fallback to knowledge base where permission will disallow access anyway
  const firstEnabledResource =
    (Object.keys(PROJECT_RESOURCE_PATH_MAP) as PROJECT_RESOURCE[]).find(
      (resource) =>
        hasFeatureAccess(resource, data.features, data.restricted_features),
    ) || PROJECT_RESOURCE.KNOWLEDGE_BASE;

  return generateNextPath(PROJECT_RESOURCE_PATH_MAP[firstEnabledResource]!, {
    accountId,
    projectId,
    conversationId: null,
    functionId: null,
    flowId: null,
  });
};

export const stripProjectRoute = (path: string) => path.replace(BASE_ROUTE, '');
