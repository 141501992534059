import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { getLanguageName } from 'utils/languageCodeUtils';
import { useDraft } from './useDraft';
import { useToast } from './useToast';

const useAssistantConfig = () => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();

  const queryClient = useQueryClient();
  const toast =  useToast();
  const { onProjectUpdate } = useDraft();

  const queryKey = [accountId, projectId, 'config'];

  const { data: config, isLoading: isLoadingConfig } = useQuery({
    queryFn: () => api.getAssistantConfig(projectId),
    queryKey,
  });

  const { mutate: updateVoice, isLoading: isUpdatingVoice } = useMutation({
    mutationKey: queryKey,
    mutationFn: (voiceId: string) =>
      api.updateAssistantVoice({ projectId, voiceId }),
    onSuccess: (config) => {
      queryClient.setQueryData(queryKey, config);
      onProjectUpdate();
    },
    onError: () => {
      toast.error({
        title: 'Failed to update voice',
      });
    },
  });

  const { mutate: updateModel, isLoading: isUpdatingModel } = useMutation({
    mutationKey: queryKey,
    mutationFn: (modelId: string) =>
      api.updateAssistantModel({ projectId, modelId }),
    onSuccess: (config) => {
      queryClient.setQueryData(queryKey, config);
      onProjectUpdate();
    },
    onError: () => {
      toast.error({
        title: 'Failed to update model',
      });
    },
  });

  const { mutate: updateConfig, isLoading: isUpdatingConfig } = useMutation({
    mutationKey: queryKey,
    mutationFn: (config: Record<string, string>) =>
      api.updateAssistantConfig({ projectId, config }),
    onSuccess: (response) => {
      const languageIsUpdated =
        config?.config?.language_code !== response?.config?.language_code;
      const newLanguageName = getLanguageName(
        response?.config?.language_code ?? config?.config?.language_code,
      );
      if (languageIsUpdated) {
        toast.warning({
          title: `Please translate the content of the Knowledge Base, Rules and About pages into ${newLanguageName} to make sure that the assistant responds in the correct language.`,
        });
      }
      queryClient.setQueryData(queryKey, response);
      onProjectUpdate();
    },
    onError: () => {
      toast.error({
        title: 'Failed to update config',
      });
    },
  });

  return {
    config,
    isLoadingConfig,
    updateVoice,
    isUpdatingVoice,
    updateModel,
    isUpdatingModel,
    updateConfig,
    isUpdatingConfig,
  };
};

export default useAssistantConfig;
