import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectInit } from 'actions';

export interface ProjectState {
  id: string;
  activeLanguageCode: string;
}

const initialState: ProjectState = {
  id: '',
  activeLanguageCode: '',
};

const jupiterProjectSlice = createSlice({
  name: 'jupiterProjectInfo',
  initialState,
  reducers: {
    setProjectId: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
    setActiveLanguageCode: (state, { payload }: PayloadAction<string>) => {
      state.activeLanguageCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const { setProjectId, setActiveLanguageCode } =
  jupiterProjectSlice.actions;

export default jupiterProjectSlice.reducer;
