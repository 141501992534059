import { components as reactSelectComponents } from "react-select";
import styled from "styled-components";

import { Loader as DefaultLoader } from "components/atoms/Loader";
import { CaptionStrong } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const DropdownIndicator = styled(
  reactSelectComponents.DropdownIndicator
)`
  svg {
    position: relative;
  }
`;

export const ErrorCaption = styled(CaptionStrong)`
  margin-top: ${themeSpacing.xs2};
  color: ${themeColors.errorText01};
`;

export const Loader = styled(DefaultLoader)`
  margin-top: ${themeSpacing.xs1};
`;
