import { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { OptionType } from '@polyai/ui/components/atoms/Select';

import api from 'api';
import { setActiveLanguageCode } from 'ducks/project/projectSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import useAssistantConfig from 'hooks/useAssistantConfig';
import { getLanguageName } from 'utils/languageCodeUtils';

const DEFAULT_LANGUAGE_CODE =
  process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE ?? 'en-GB';

const DEFAULT_LANGUAGE_NAME =
  getLanguageName(DEFAULT_LANGUAGE_CODE) ?? DEFAULT_LANGUAGE_CODE === 'en-GB'
    ? 'English (United Kingdom)'
    : 'English (United States)';
const useLanguages = () => {
  const accountId = useAppSelector((state) => state.account.id);
  const activeLanguageCode = useAppSelector(
    (state) => state.project.activeLanguageCode,
  );
  const queryKey = [accountId, 'languages'];
  const dispatch = useDispatch();
  const { config, updateConfig, isLoadingConfig } = useAssistantConfig();

  const handleSetActiveLanguage = useCallback(
    (language: OptionType) => {
      dispatch(setActiveLanguageCode(language.value));
      updateConfig({
        language_code: language.value,
      });
    },
    [dispatch],
  );

  const { data: languages, isLoading } = useQuery({
    queryFn: () => api.getLanguages(),
    queryKey: queryKey,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!accountId,
  });

  const defaultActiveLanguage = useMemo(() => {
    let language_code = DEFAULT_LANGUAGE_CODE;
    if (config?.config?.language_code) {
      language_code = config.config.language_code;
    } else if (activeLanguageCode.length > 0) {
      language_code = activeLanguageCode;
    }
    const language_name =
      getLanguageName(language_code) ?? DEFAULT_LANGUAGE_NAME;

    return {
      label: language_name,
      value: language_code,
    };
  }, [config?.config]);

  useEffect(() => {
    if (!defaultActiveLanguage) return;
    dispatch(setActiveLanguageCode(defaultActiveLanguage.value));
  }, [defaultActiveLanguage, dispatch]);

  const languageSelectOptions: OptionType[] = useMemo(
    () =>
      languages?.languages?.map((option) => ({
        value: option.code,
        label: option.name,
      })) ?? [],
    [languages?.languages],
  );

  return {
    supportedLanguages: languages?.languages ?? [],
    isFetchingLanguages: isLoading,
    activeLanguage: defaultActiveLanguage,
    isLoadingActiveLanguage: isLoadingConfig,
    defaultLanguageCode: DEFAULT_LANGUAGE_CODE,
    languageSelectOptions,
    handleSetActiveLanguage,
  };
};

export default useLanguages;
