import { memo, ReactNode, useCallback } from "react";

import { Node as MenuNode } from "@polyai/common/types/helpers";

import Checkbox, { CheckboxState } from "components/atoms/Checkbox";
import { Caption } from "components/atoms/Text";
import { PopupMenu, PopupMenuType } from "components/molecules/PopupMenu";

import * as Styled from "./HierarchicalMenu.styled";

export interface HierarchicalMenuProps<T extends string> extends PopupMenuType {
  names: { [P in T]: string };
  values: MenuNode<T>[];
  checkedValues: T[];
  handleCheck: (values: T[], checked: boolean) => void;
  groupParentValues?: T[]; // for grouping values without being a toggle-able value itself
}

export const getSubtreeValues = <T extends string>(node: MenuNode<T>): T[] => {
  if (typeof node === "string") {
    return [node];
  }

  const [parent, children] = Object.entries(node)[0] as [T, MenuNode<T>[]];
  return [parent, ...children.flatMap((child) => getSubtreeValues(child))];
};

const HierarchicalMenu = <T extends string>({
  names,
  values,
  checkedValues,
  handleCheck,
  groupParentValues,
  ...props
}: HierarchicalMenuProps<T>) => {
  const getIsValueChecked = useCallback(
    (value: T) => checkedValues.includes(value),
    [checkedValues]
  );

  const generateHierarchicalMenu = useCallback(
    (node: MenuNode<T>, depth: number, path: T[]): ReactNode => {
      // resolve leaf nodes
      if (typeof node === "string") {
        const value = node;
        const isValueChecked = getIsValueChecked(value);

        // if already checked, only uncheck this leaf node
        // else if unchecked, check all required parent nodes
        const valuesToToggle = isValueChecked ? [value] : [...path, value];

        return (
          <Styled.NodeMenuItem
            key={node}
            $depth={depth}
            onClick={() => handleCheck(valuesToToggle, !isValueChecked)}
          >
            <Checkbox
              state={
                isValueChecked ? CheckboxState.CHECKED : CheckboxState.UNCHECKED
              }
              isDarkBackground
            />
            <Caption>{names[node] || value}</Caption>
          </Styled.NodeMenuItem>
        );
      }

      // resolve parent nodes
      const subtreeValues = getSubtreeValues(node);
      const parentValue = subtreeValues[0];

      const isParentGroup = groupParentValues?.includes(parentValue);

      const isParentValueChecked = isParentGroup
        ? subtreeValues.slice(1).every((value) => getIsValueChecked(value))
        : getIsValueChecked(parentValue);

      const isSomeChildrenValueChecked = subtreeValues
        .slice(1)
        .some((value) => getIsValueChecked(value));

      // if already checked or a parent group, toggle this node and all children nodes
      // else if unchecked, check all required parent nodes
      const valuesToToggle =
        isParentGroup || isParentValueChecked
          ? subtreeValues
          : [...path, parentValue];

      const childrenNodes = Object.values(node)[0] as MenuNode<T>[];

      return (
        <Styled.TreeContainer key={parentValue}>
          <Styled.ParentNodeMenuItem
            key={parentValue}
            $depth={depth}
            onClick={() => handleCheck(valuesToToggle, !isParentValueChecked)}
          >
            <Checkbox
              state={
                isParentValueChecked
                  ? CheckboxState.CHECKED
                  : isSomeChildrenValueChecked
                  ? CheckboxState.INDETERMINATE
                  : CheckboxState.UNCHECKED
              }
              isDarkBackground
            />
            <Caption>{names[parentValue] || parentValue}</Caption>
          </Styled.ParentNodeMenuItem>
          {childrenNodes.map((childNode) =>
            generateHierarchicalMenu(childNode, depth + 1, [
              ...path,
              parentValue,
            ])
          )}
        </Styled.TreeContainer>
      );
    },
    [getIsValueChecked, handleCheck, names]
  );

  return (
    <PopupMenu {...props}>
      {values.map((value) => generateHierarchicalMenu(value, 0, []))}
    </PopupMenu>
  );
};

export default memo(HierarchicalMenu);
