import { toast } from 'sonner';

import { usePathname } from '@polyai/common/hooks/useRouter';

import { logToDataDog } from 'lib/ddlogHelper';
import { getPageTab } from 'lib/miscHelper';
import { Alert, TOAST_DURATION } from '../components/atoms/Alert';
import {
  AlertProps as Props,
  AlertType,
} from '../components/atoms/Alert/types';

export enum ALERT_LOGGER_TYPE {
  ERROR = 'errorAlertLogger',
  INFO = 'infoAlertLogger',
  WARNING = 'warningAlertLogger',
  SUCCESS = 'successAlertLogger',
}

type ToastProps = Pick<Props, 'type' | 'title' | 'description'>;

type ToastAlertProps = Omit<Props, 'type'>;

export type Toast = ReturnType<typeof useToast>;

const TOAST_ERROR_DURATION = TOAST_DURATION * 20;

const getLoggerType = (type: AlertType) => {
  if (type === 'info') return ALERT_LOGGER_TYPE.INFO;
  else if (type === 'success') return ALERT_LOGGER_TYPE.SUCCESS;
  else if (type === 'warning') return ALERT_LOGGER_TYPE.WARNING;
  else return ALERT_LOGGER_TYPE.ERROR;
};

const getAdjustedType = (type: AlertType) => {
  if (type === 'info') return 'info';
  else if (type === 'success') return 'info';
  else if (type === 'warning') return 'warn';
  else return 'error';
};

const logToLogger = ({
  pathname,
  type,
  title,
  description,
}: {
  pathname: string;
} & ToastProps) => {
  if (type === 'info') return;

  const page = getPageTab(pathname);
  const loggerType = getLoggerType(type);
  const adjustedType = getAdjustedType(type);

  logToDataDog(loggerType, adjustedType, title, {
    page,
    alert_message: title,
    alert_title: description,
  });
};

export const useToast = () => {
  const pathname = usePathname();

  const show = (props: Props) => {
    logToLogger({
      pathname,
      ...props,
    });

    toast.custom((t) => <Alert onClose={() => toast.dismiss(t)} {...props} />, {
      duration: props.type !== 'error' ? TOAST_DURATION : TOAST_ERROR_DURATION,
    });
  };

  const info = (props: ToastAlertProps) =>
    show({
      type: 'info',
      ...props,
    });

  const success = (props: ToastAlertProps) =>
    show({
      type: 'success',
      ...props,
    });

  const warning = (props: ToastAlertProps) =>
    show({
      type: 'warning',
      ...props,
    });

  const error = (props: ToastAlertProps) =>
    show({
      type: 'error',
      ...props,
    });

  return {
    info,
    success,
    warning,
    error,
  };
};
