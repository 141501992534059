import styled, { css } from "styled-components";

import { IconSize } from "components/atoms/Icons/BaseIcon";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import rem from "styles/functions/rem";
import { PopupMenuItem } from "../PopupMenu";

const BASE_PADDING_LEFT = 16;

export const TreeContainer = styled.div`
  position: relative;
`;

export const NodeMenuItem = styled(PopupMenuItem)<{ $depth: number }>`
  display: flex;
  gap: ${themeSpacing.xs2};
  padding-left: ${({ $depth }) =>
    rem(BASE_PADDING_LEFT + IconSize.md * $depth)};

  ${({ $depth }) =>
    $depth === 0 &&
    css`
      border-top: 1px solid ${themeColors.borderStrongInverse};
    `}
`;

export const ParentNodeMenuItem = styled(NodeMenuItem)<{ $depth: number }>`
  ${({ $depth }) =>
    $depth === 0 &&
    css`
      /* overrides implicit first-child border-top being set to none  */
      border-top: 1px solid ${themeColors.borderStrongInverse} !important;
    `}

  /* the divider line indicating tree level */
  ::after {
    content: "";

    position: absolute;
    z-index: 1;
    bottom: ${themeSpacing.xs2};

    /* push to horizontally align with the parent checkbox above */
    left: ${({ $depth }) =>
      rem(BASE_PADDING_LEFT + (IconSize.md * ($depth + 1) - IconSize.md / 2))};

    /* trim line to fit under parent checkbox and take padding into account */
    height: calc(
      100% - ${IconSize.md}px - ${themeSpacing.sm1} - ${themeSpacing.xs2}
    );

    border-left: 1px solid ${themeColors.borderStrongInverse};
  }
`;
