import ENDPOINTS from 'api/endpoints';
import { doGet, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  AssistantConfig,
  ModelOptionsResponse,
  VoiceOptionsResponse,
} from './types';

export const getVoiceOptions = (accountId: string) => {
  const projectId = store.getState().project.id;
  return doGet<VoiceOptionsResponse>('voices', LOGGER_TYPE.JUPITER, {
    params: { account_id: accountId, project_id: projectId },
  });
};

export const updateAssistantVoice = ({
  projectId,
  voiceId,
}: {
  projectId: string;
  voiceId: string;
}) => {
  const accountId = store.getState().account.id;

  return doPut<AssistantConfig>(
    generateNextPath(ENDPOINTS.VOICE, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      voice_id: voiceId,
    },
    { version: 'v2' },
  );
};

export const getModelOptions = (accountId: string) => {
  return doGet<ModelOptionsResponse>('models', LOGGER_TYPE.JUPITER, {
    params: { account_id: accountId },
  });
};

export const updateAssistantModel = ({
  projectId,
  modelId,
}: {
  projectId: string;
  modelId: string;
}) => {
  const accountId = store.getState().account.id;

  return doPut<AssistantConfig>(
    generateNextPath(ENDPOINTS.MODEL, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      model_id: modelId,
    },
    { version: 'v2' },
  );
};

export const getAssistantConfig = (projectId: string) => {
  const accountId = store.getState().account.id;

  return doGet<AssistantConfig>(
    generateNextPath(ENDPOINTS.CONFIG, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const updateAssistantConfig = ({
  projectId,
  config,
}: {
  projectId: string;
  config: Record<string, string>;
}) => {
  const accountId = store.getState().account.id;

  return doPut<AssistantConfig>(
    generateNextPath(ENDPOINTS.CONFIG, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    config,
    { version: 'v2' },
  );
};
