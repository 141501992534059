import { ClientEnv } from 'constants/Chat.constants';
import { useQueryClient } from '@tanstack/react-query';

import { resetChat } from 'actions';
import { ProjectWithTokenExceeded } from 'api/types';
import { setClientEnv } from 'ducks/chatPanel/chatPanelSlice';
import { getProjectQueryKey } from 'utils/query';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export const useDraft = () => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const { id: accountId } = useAppSelector((state) => state.account);
  const { id: projectId } = useAppSelector((state) => state.project);

  const setProjectIsDraft = () => {
    queryClient.setQueryData(
      getProjectQueryKey(accountId, projectId),
      (data: ProjectWithTokenExceeded | undefined) => {
        if (!data) return;
        return { ...data, is_draft: true };
      },
    );
  };

  const onProjectUpdate = () => {
    setProjectIsDraft();

    // get token limit status
    queryClient.invalidateQueries(getProjectQueryKey(accountId, projectId));

    dispatch(resetChat(ClientEnv.DRAFT));
    dispatch(setClientEnv(ClientEnv.DRAFT));
  };

  return {
    onProjectUpdate,
  };
};
