import { useMemo } from 'react';

import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ClientEnvironment } from '@polyai/common/constants/global.constants';
import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { CountryCode, PhoneNumberType } from 'api/resources/phoneNumber/types';
import { useToast } from 'hooks/useToast';
import useSampleProject from './useSampleProject';

export const PHONE_NUMBER_MUTATION_KEY = 'phone-number';
type PhoneNumberToDelete = {
  clientEnv: ClientEnvironment;
  phoneNumber: string;
};
// TODO: Change how fetching number per client environment is done
// once we enable purchasing numbers per client environment
const usePhoneNumber = (clientEnv?: ClientEnvironment) => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();
  const { inSampleProject } = useSampleProject();
  const toast = useToast();
  const queryClient = useQueryClient();

  const queryKey = [accountId, projectId, clientEnv, PHONE_NUMBER_MUTATION_KEY];
  const allPhoneNumbersQueryKey = [
    accountId,
    projectId,
    'phone-number',
    'all-environments',
  ];

  const {
    data,
    isLoading: isLoadingPhoneNumber,
    isError: isPhoneNumberError,
  } = useQuery({
    queryKey,
    queryFn: () => api.getPhoneNumber(projectId, clientEnv),
    enabled: !inSampleProject,
  });

  const { data: allPhoneNumbers } = useQuery({
    queryKey: allPhoneNumbersQueryKey,
    queryFn: () => api.getPhoneNumbersInAllEnvironments(projectId),
    enabled: !inSampleProject,
  });

  const mutationKey = [accountId, projectId, PHONE_NUMBER_MUTATION_KEY];

  const { mutate: buyPhoneNumber } = useMutation({
    mutationKey,
    mutationFn: ({
      countryCode,
      clientEnv,
    }: {
      countryCode: CountryCode;
      clientEnv: ClientEnvironment;
    }) => api.buyPhoneNumber(projectId, countryCode, clientEnv),
    onSuccess: (_, { clientEnv }) => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(allPhoneNumbersQueryKey);
      toast.success({
        title: `${clientEnv} number added`,
      });
    },
    onError: () =>
      toast.error({
        title: `Failed to purchase number`,
      }),
  });

  const isMutating = useIsMutating({ mutationKey });

  const { sandBoxNumbers, preReleasePhoneNumbers, livePhoneNumbers } =
    useMemo(() => {
      const result: Record<string, PhoneNumberType[]> = {
        sandBoxNumbers: [],
        preReleasePhoneNumbers: [],
        livePhoneNumbers: [],
      };
      allPhoneNumbers?.phone_numbers?.forEach((item) => {
        const arrayToPushIn =
          item.client_env === ClientEnvironment.SANDBOX
            ? result.sandBoxNumbers
            : item.client_env === ClientEnvironment.PRE_RELEASE
            ? result.preReleasePhoneNumbers
            : result.livePhoneNumbers;

        arrayToPushIn.push(item);
      });

      return result;
    }, [allPhoneNumbers]);

  const { mutate: deletePhoneNumber } = useMutation({
    mutationKey,
    mutationFn: ({ phoneNumber, clientEnv }: PhoneNumberToDelete) =>
      api.deletePhoneNumber(phoneNumber, clientEnv),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(allPhoneNumbersQueryKey);
      toast.success({
        title: `phone number has been deleted`,
      });
    },
    onError: () =>
      toast.error({
        title: `Failed to delete phone number`,
      }),
  });
  return {
    phoneNumber: data?.phone_number,
    isLoadingPhoneNumber,
    isPhoneNumberError,
    buyPhoneNumber,
    sandBoxNumbers,
    preReleasePhoneNumbers,
    livePhoneNumbers,
    isBuyingPhoneNumber: !!isMutating,
    deletePhoneNumber,
  };
};

export default usePhoneNumber;
