import styled from 'styled-components';

import {
  CloseIcon,
  ErrorIconRound,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from '@polyai/ui/components/atoms/Icons';
import { BodyStrong } from '@polyai/ui/components/atoms/Text';
import text from '@polyai/ui/styles/config/text';

import { IconButton } from 'components/atoms/IconButton';

import { AlertProps, AlertType } from './types';

import * as Styled from './Alert.styled';

export const TOAST_DURATION = 5000; // In milliseconds

const Title = styled(BodyStrong)`
  font-size: ${text.size.body.body2};
  font-weight: ${text.weight.semibold};
`;

const Description = styled(BodyStrong)`
  font-size: ${text.size.body.body3};
  font-weight: ${text.weight.regular};
`;

const AlertMessage = ({
  title,
  description,
}: Pick<AlertProps, 'title' | 'description'>) => {
  return (
    <Styled.MessageContainer>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Styled.MessageContainer>
  );
};

const getIcon = (type: AlertType) => {
  switch (type) {
    case 'info':
      return <InfoIcon color="iconInfo" customFill />;
    case 'success':
      return <SuccessIcon color="iconSuccess" customFill />;
    case 'error':
      return <ErrorIconRound color="iconDanger" customFill />;
    case 'warning':
      return <WarningIcon color="iconWarning" customFill />;
    default:
      return null;
  }
};

export const Alert = ({ type, title, description, onClose }: AlertProps) => (
  <Styled.AlertContainer data-test-id="alert-message" type={type}>
    <Styled.AlertIcon>{getIcon(type)}</Styled.AlertIcon>
    <Styled.AlertBody>
      <AlertMessage description={description} title={title} />
      <IconButton data-test-id="alert-window-close-button" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Styled.AlertBody>
  </Styled.AlertContainer>
);
