import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UPLOAD_STATUS_NOT_UPLOADING } from '@polyai/common/constants/Screen.constants';

import { UploadType } from 'components/organisms/ImportTopicsPanel/ImportTopicsPanel.constants';

import { resetUploadStatus } from 'actions';

export interface UploadPanelState {
  isUploadPanelOpen: {
    [key in UploadType]: boolean;
  };
  uploadsInProgress: { [UploadType.TOPICS]: boolean };
  uploadStatus: {
    [UploadType.TOPICS]: {
      status: string;
      error: boolean;
      numberOfFiles: number;
    };
  };
}

const initialState: UploadPanelState = {
  isUploadPanelOpen: {
    [UploadType.TOPICS]: false,
  },
  uploadsInProgress: {
    [UploadType.TOPICS]: false,
  },
  uploadStatus: {
    [UploadType.TOPICS]: {
      status: UPLOAD_STATUS_NOT_UPLOADING,
      error: false,
      numberOfFiles: 0,
    },
  },
};

const uploadPanelSlice = createSlice({
  name: 'uploadPanel',
  initialState,
  reducers: {
    openUploadPanel: (state, data: PayloadAction<UploadType>) => {
      state.isUploadPanelOpen[data.payload] = true;
    },
    closeUploadPanel: (state, data: PayloadAction<UploadType>) => {
      state.isUploadPanelOpen[data.payload] = false;
    },
    startUploading: (state) => {
      state.uploadsInProgress[UploadType.TOPICS] = true;
    },
    setUploadStatus: (
      state,
      data: PayloadAction<{
        uploadType: UploadType.TOPICS;
        data: {
          status: string;
          error: boolean;
          numberOfFiles: number;
        };
      }>,
    ) => {
      const { payload } = data;
      state.uploadStatus[payload.uploadType] = payload.data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      resetUploadStatus,
      (state, data: PayloadAction<UploadType | undefined>) => {
        const uploadType = data.payload;
        if (uploadType) {
          state.uploadStatus[uploadType] = {
            status: UPLOAD_STATUS_NOT_UPLOADING,
            error: false,
            numberOfFiles: 0,
          };
          state.uploadsInProgress[uploadType] = false;
        }
      },
    );
  },
});

export const {
  openUploadPanel,
  closeUploadPanel,
  startUploading,
  setUploadStatus,
} = uploadPanelSlice.actions;

export default uploadPanelSlice.reducer;
