import { useMemo, useRef } from 'react';

import {
  Mutation,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { ComponentWithChildren } from '@polyai/common/types/helpers';

import { setSavedState } from 'ducks/layout/layoutSlice';
import { ANALYTICS_MUTATION_KEY } from 'hooks/useAnalytics/useLuzmoAuth';
import { ASSISTANT_CHAT_MUTATION_KEY } from 'hooks/useChat';
import { DEPLOYMENTS_MUTATION_KEY } from 'hooks/useDeployments/useDeployments';
import { DEPLOY_FUNCTIONS_MUTATION_KEY } from 'hooks/useFunctions/useFunctionActions';
import { PAYMENTS_MUTATION_KEY } from 'hooks/usePayment';
import { PHONE_NUMBER_MUTATION_KEY } from 'hooks/usePhoneNumber';
import { PROJECT_MUTATION_KEY } from 'hooks/useProject';
import { SMS_CREDENTIALS_MUTATION_KEY } from 'hooks/useSmsCredentials';
import { TELEPHONE_MUTATION_KEY } from 'hooks/useTelephony';
import { useToast } from 'hooks/useToast';
import { VOICE_TUNING_MUTATION_KEY } from 'hooks/useVoiceTuningSettings/useVoiceTuningSettings';
import { HTTPError } from './error';
import { handleGlobalErrors } from './response';

const IGNORE_MUTATIONS = [
  ANALYTICS_MUTATION_KEY,
  PAYMENTS_MUTATION_KEY,
  ASSISTANT_CHAT_MUTATION_KEY,
  DEPLOY_FUNCTIONS_MUTATION_KEY,
  DEPLOYMENTS_MUTATION_KEY,
  VOICE_TUNING_MUTATION_KEY,
  PROJECT_MUTATION_KEY,
  PHONE_NUMBER_MUTATION_KEY,
  TELEPHONE_MUTATION_KEY,
  SMS_CREDENTIALS_MUTATION_KEY,
];

const shouldIgnore = (mutation: Mutation<unknown, unknown, unknown, unknown>) =>
  mutation.options.mutationKey &&
  IGNORE_MUTATIONS.some((key) => mutation.options.mutationKey?.includes(key));

const CustomQueryClientProvider: ComponentWithChildren = ({ children }) => {
  const dispatch = useDispatch();
  const numMutations = useRef(0);
  const savedTimeout = useRef<NodeJS.Timeout | undefined>();

  const toast = useToast();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onMutate: (_, mutation) => {
            if (shouldIgnore(mutation)) {
              return;
            }
            if (!numMutations.current) {
              dispatch(setSavedState('saving'));
            }
            numMutations.current += 1;
          },
          onSuccess: (_, __, ___, mutation) => {
            if (shouldIgnore(mutation)) {
              return;
            }
            dispatch(setSavedState('saved'));
            clearTimeout(savedTimeout.current);
            savedTimeout.current = setTimeout(() => {
              dispatch(
                setSavedState(0 < numMutations.current ? 'saving' : 'idle'),
              );
              savedTimeout.current = undefined;
            }, 3000);
          },
          onError: (error, __, ___, mutation) => {
            handleGlobalErrors(error as HTTPError, mutation, toast);

            if (shouldIgnore(mutation)) {
              return;
            }
            if (savedTimeout.current) {
              return;
            }
            dispatch(
              setSavedState(1 < numMutations.current ? 'saving' : 'idle'),
            );
          },
          onSettled: (_, __, ___, ____, mutation) => {
            if (shouldIgnore(mutation)) {
              return;
            }
            numMutations.current = Math.max(0, numMutations.current - 1);
          },
        }),
        defaultOptions: {
          queries: {
            cacheTime: 300000,
            staleTime: 60000,
            retry: false,
          },
        },
      }),
    // adding toast as dep causes querying issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
