import {
  SUPPORT_EMAIL,
  SUPPORT_SUBJECT,
  SUPPORT_TEXT,
  SUPPORT_TITLE,
} from 'constants/EmailUs.constants';
import { useAuth0 } from '@auth0/auth0-react';
import { createPortal } from 'react-dom';

import useLockScroll from '@polyai/common/hooks/useLockScroll';
import { createSupportBody } from '@polyai/common/lib/emailHelper';
import Button from '@polyai/ui/components/atoms/Button';
import { Body } from '@polyai/ui/components/atoms/Text';

import LandingNav from 'components/molecules/LandingNav';
import EmailUsModal from 'components/organisms/EmailUsModal';

import BottomDots from './bottomDots';
import TopDots from './topDots';
import { ErrorPageProps, ErrorStatus } from './types';
import VerifyEmail from './VerifyEmail';

import * as Styled from './JupiterErrorPage.styled';

export { ErrorStatus } from './types';

const getErrorDetails = (type: ErrorStatus) => {
  switch (type) {
    case ErrorStatus.UNAVAILABLE:
      return {
        title: 'PolyAI Jupiter is currently getting a tune-up',
        text: 'We will be back soon! Contact your PolyAI support team for more information.',
        showRefresh: true,
        showContact: false,
      };
    case ErrorStatus.UNHANDLED_ERROR:
      return {
        title: 'Something went wrong!',
        text: 'Contact your PolyAI support team if this issue persists.',
        showRefresh: true,
        showContact: true,
      };
    case ErrorStatus.FORBIDDEN:
      return {
        title: "You don't have permission to access this resource",
        text: 'Please contact your administrator for further access.',
        showRefresh: false,
        showContact: false,
      };
    case ErrorStatus.UNAUTHORIZED:
      return {
        title: 'We were unable to authorise your user account.',
        text: 'You may need to request access. Please contact your PolyAI support team.',
        showRefresh: false,
        showContact: false,
      };
    case ErrorStatus.NOT_FOUND:
      return {
        title: 'Page not found',
        text: "We can't find the page you're looking for.",
        showRefresh: false,
        showContact: false,
      };
    default:
      return {
        title: 'Something went wrong!',
        text: 'Contact your PolyAI support team if this issue persists.',
        showRefresh: true,
        showContact: true,
      };
  }
};

const ErrorPage: React.FC<ErrorPageProps> = ({ type }) => {
  const { user } = useAuth0();

  useLockScroll();

  if (type === ErrorStatus.EMAIL_UNVERIFIED) {
    return <VerifyEmail />;
  }

  const { title, text, showRefresh, showContact } = getErrorDetails(type);

  return createPortal(
    <Styled.Container>
      <Styled.DotsContainer>
        <TopDots />
        <BottomDots />
      </Styled.DotsContainer>
      <Styled.PageContainer>
        <LandingNav isErrorPage />
        <Styled.ContentContainer>
          <Styled.TextContainer>
            <Styled.Title>{title}</Styled.Title>
            <Body>{text}</Body>
          </Styled.TextContainer>
          <Styled.ButtonContainer>
            {showRefresh && (
              <Button
                label="Reload page"
                onClick={() => window.location.reload()}
              />
            )}
            {showContact && (
              <EmailUsModal
                body={createSupportBody(
                  undefined,
                  user?.given_name,
                  user?.family_name,
                )}
                description={SUPPORT_TEXT}
                email={SUPPORT_EMAIL}
                subject={SUPPORT_SUBJECT}
                title={SUPPORT_TITLE}
                trigger={<Button label="Contact support" inverse />}
              />
            )}
          </Styled.ButtonContainer>
        </Styled.ContentContainer>
      </Styled.PageContainer>
    </Styled.Container>,
    document.body,
  );
};

export default ErrorPage;
