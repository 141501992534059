import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { HTTPError } from 'api/error';
import {
  SmsCredentialsRequest,
  SmsCredentialsResponse,
} from 'api/resources/smsCredentials/types';
import {
  PROJECT_RESOURCE,
  useProjectResource,
} from 'hooks/useProjectResources';
import useSampleProject from 'hooks/useSampleProject';
import { useToast } from 'hooks/useToast';

const DEFAULT_SMS_INTEGRATION = 'twilio';

interface UpsertSmsCredentialsArgs extends SmsCredentialsRequest {
  isEdit: boolean;
}

export const SMS_CREDENTIALS_MUTATION_KEY = 'smsCredentials';

const useSmsTemplates = (integration: string = DEFAULT_SMS_INTEGRATION) => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();
  const { inSampleProject } = useSampleProject();
  const { isProjectResourceEnabled } = useProjectResource();
  const isEnabled = isProjectResourceEnabled(PROJECT_RESOURCE.SMS);

  const toast = useToast();

  const queryKey = [accountId, projectId, SMS_CREDENTIALS_MUTATION_KEY];

  const queryClient = useQueryClient();

  const { data: smsCredentials, isLoading: isLoadingSmsCredentials } = useQuery(
    queryKey,
    () => api.getSmsCredentials(integration),
    {
      enabled: isEnabled && !inSampleProject,
      retry: false,
    },
  );

  const { mutate: upsertSmsCredentials, isLoading: isUpsertingSmsCredentials } =
    useMutation<SmsCredentialsResponse, HTTPError, UpsertSmsCredentialsArgs>(
      (request) => api.upsertSmsCredentials(request, integration),
      {
        retry: false,
        mutationKey: queryKey,
        onSuccess: (data, args) => {
          queryClient.setQueryData(queryKey, data);

          if (args.isEdit) {
            toast.success({
              title: 'Successfully updated SMS credentials',
            });
          } else {
            toast.success({
              title: 'Successfully added SMS credentials',
            });
          }
        },
        onError: (_, args) => {
          if (args.isEdit) {
            toast.error({
              title: 'Failed to update SMS credentials',
            });
          } else {
            toast.error({
              title: 'Failed to add SMS credentials',
            });
          }
        },
      },
    );

  return useMemo(
    () => ({
      smsCredentials,
      isLoadingSmsCredentials,
      upsertSmsCredentials,
      isUpsertingSmsCredentials,
    }),
    [
      smsCredentials,
      isLoadingSmsCredentials,
      upsertSmsCredentials,
      isUpsertingSmsCredentials,
    ],
  );
};

export default useSmsTemplates;
