import styled from 'styled-components';

import Clickable from '@polyai/ui/components/atoms/Clickable';
import { Body, Title } from '@polyai/ui/components/atoms/Text';
import themeColors, { baseColors } from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const Description = styled(Body)`
  margin-top: ${themeSpacing.sm1};
`;

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: ${themeSpacing.sm4};
  padding: ${themeSpacing.xs3} ${themeSpacing.sm1};

  background: ${themeColors.backgroundPage};
  border-radius: ${themeSpacing.radiusMedium};
`;

export const EmailContent = styled(Clickable)`
  display: flex;
  gap: ${themeSpacing.sm1};
  align-items: center;
`;

export const Email = styled(Title)`
  padding-right: ${themeSpacing.xs2};
  color: ${baseColors.brand[50]};
`;
