import Popup from "reactjs-popup";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export enum TooltipSize {
  sm = "small",
  lg = "large",
}

export const TooltipPopup = styled(Popup)<{
  $size: keyof typeof TooltipSize;
  $maxWidth?: number;
  $borderRadius: number;
  $fitContent: boolean;
}>`
  &-content {
    font-size: ${({ theme }) => theme.text.size.body.body3};
    color: ${themeColors.textPrimaryInverse};
    background: ${themeColors.backgroundInverse};
    border-radius: ${({ $borderRadius }) => $borderRadius}px !important;
    ${({ $size }) =>
      $size === "lg"
        ? css`
            min-width: 130px;
            padding: ${themeSpacing.sm1};
          `
        : css`
            width: fit-content !important;
            padding: ${themeSpacing.xs1} ${themeSpacing.xs3};
          `};
    ${({ $maxWidth }) =>
      $maxWidth &&
      css`
        max-width: ${$maxWidth}px;
      `}
  }

  &-arrow {
    color: ${themeColors.backgroundInverse};
    filter: none;
  }
`;

export const TooltipContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TriggerWrapper = styled.div<{
  $isDisabled?: boolean;
  $inline?: boolean;
}>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  display: ${({ $inline }) => ($inline ? "inline-block" : "flex")};
  width: fit-content;
  min-width: 0;

  > * {
    cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;
