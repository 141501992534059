import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPatch, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import {
  CreateProjectReq,
  ProjectDetails,
  ProjectRes,
  ProjectResourceRes,
  ProjectsRes,
  ProjectWithTokenExceeded,
  UpdateProject,
} from './types';

export const getProjects = (accountId: string) => {
  return doGet<ProjectsRes>(
    generateNextPath(ENDPOINTS.PROJECTS, {
      accountId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const getProject = (accountId: string, projectId: string) => {
  return doGet<ProjectWithTokenExceeded>(
    generateNextPath(ENDPOINTS.PROJECT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const deleteProject = (accountId: string, projectId: string) => {
  return doDelete<{ success: boolean }>(
    generateNextPath(ENDPOINTS.PROJECT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const duplicateProject = (accountId: string, projectId: string) => {
  return doPost<{ success: boolean }>(
    generateNextPath(ENDPOINTS.DUPLICATE_PROJECT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    undefined,
    { version: 'v2' },
  );
};

export const updateProject = (
  accountId: string,
  projectId: string,
  update: UpdateProject,
) => {
  return doPatch<ProjectRes>(
    generateNextPath(ENDPOINTS.PROJECT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    update,
    { version: 'v2' },
  );
};

export const createProject = (accountId: string, body: CreateProjectReq) => {
  return doPost<ProjectDetails>(
    generateNextPath(ENDPOINTS.PROJECTS, {
      accountId,
    }),
    LOGGER_TYPE.JUPITER,
    body,
    { version: 'v2' },
  );
};

export const publishDraft = (
  accountId: string,
  projectId: string,
  deploymentMessage: string,
) => {
  return doPost<ProjectRes>(
    generateNextPath(ENDPOINTS.PUBLISH, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      deployment_message: deploymentMessage,
      client_env: ClientEnvironment.SANDBOX,
    },
    { version: 'v2' },
  );
};

export const deleteDraft = (accountId: string, projectId: string) => {
  return doDelete<ProjectRes>(
    generateNextPath(ENDPOINTS.DRAFT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const getEnabledFeatures = (accountId: string, projectId?: string) => {
  return doGet<ProjectResourceRes>(
    generateNextPath(ENDPOINTS.PROJECT_FEATURES, {
      accountId,
      projectId: projectId!,
    }),
    LOGGER_TYPE.PROJECT,
    // { version: 'v2' },
  );
};
